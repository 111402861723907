<div class="pagination-container">
	<nav aria-label="Pagination">
		<ul class="pagination">
			<li class="page-item" [class.disabled]="currentPage === 1">
				<a class="page-link" (click)="prevPage()">Previous</a>
			</li>
			<li class="page-item" *ngFor="let page of pageNumbers" [class.active]="page === currentPage">
				<a class="page-link" *ngIf="page !== -1" (click)="setPage(page)">{{ page }}</a>
				<span class="page-link" *ngIf="page === -1">...</span>
			</li>
			<li class="page-item" [class.disabled]="currentPage === getTotalPages()">
				<a class="page-link" (click)="nextPage()">Next</a>
			</li>
		</ul>
	</nav>
</div>
