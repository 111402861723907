import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AdvertiseModel } from './../../models/advertise/advertise.model';
import { firstValueFrom } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class AdvertiseService {
	baseUrl = environment.ApiUrl;

	constructor(private _httpClient: HttpClient) {}
	/**
	 * @method {GET}
	 * @param {}
	 * @route  'advertise'
	 * @returns {Promise<AdvertiseModel>}
	 * @description get advertise details results
	 */

	async getAdvertiseDetails(): Promise<AdvertiseModel> {
		try {
			return await firstValueFrom(this._httpClient.get<AdvertiseModel>(`${this.baseUrl}advertise`));
		} catch (error) {}
	}
}
