import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CompanyModel } from '../../core/models/company/company.model';
import { NgxSpinnerService } from 'ngx-spinner';
import { MicrositeDataModel, MicrositeDetail, RelatedCategory } from '../../core/models/microSite/micro.site.model';
import { MicrositeService } from '../../core/services/microsite/microsite.service';
import { DomSanitizer, SafeResourceUrl, Meta, Title } from '@angular/platform-browser';
import { Lightbox } from 'ngx-lightbox';

@Component({
	selector: 'app-microsite',
	templateUrl: './microsite.component.html',
	styleUrls: ['./microsite.component.scss'],
})
export class MicrositeComponent implements OnInit {
	micrositeId: string = '';
	directory: string = '';
	searchTerm: string = '';
	company: CompanyModel;
	location: string = '';
	result: MicrositeDataModel;
	companyDetails: MicrositeDetail[] = [];
	preferredBusinesses: any[] = [];
	quickLinks: any[] = [];
	relatedCategories: any[] = [];
	latitude: number = 0;
	longitude: number = 0;
	IsDisplayOpenNow = false;
	private _imageAlbums: any[] = [];
	private _adAlbums: any[] = [];

	@Output() searchTermSelected: EventEmitter<string> = new EventEmitter<string>();

	constructor(
		private micrositeDetailsService: MicrositeService,
		private spinner: NgxSpinnerService,
		private route: ActivatedRoute,
		private router: Router,
		private sanitizer: DomSanitizer,
		private _lightbox: Lightbox,
		private meta: Meta,
		private title: Title
	) {}

	ngOnInit(): void {
		this.route.paramMap.subscribe((params) => {
			this.micrositeId = params.get('id');
			this.searchTerm = this.route.snapshot.paramMap.get('searchTerm') || '';
			this.directory = this.route.snapshot.paramMap.get('directory') || '';
			this.location = this.route.snapshot.paramMap.get('location') || '';
			//console.log('Microsite ID:', this.micrositeId);
		});

		this.getMicrositeDetails();
	}

	open(index: number, type: 'image' | 'ad'): void {
		let items;

		if (type === 'image') {
			items = this._imageAlbums;
		} else {
			items = this._adAlbums;
		}

		this._lightbox.open(items, index, {
			centerVertically: true,
			fadeDuration: 0.2,
		});
	}

	close(): void {
		// close lightbox programmatically
		this._lightbox.close();
	}

	async getMicrositeDetails() {
		this.spinner.show();
		try {
			if (this.location == 'Everywhere') {
				this.result = await this.micrositeDetailsService.getMicrSiteDetails('', this.searchTerm, this.micrositeId);
			} else {
				this.result = await this.micrositeDetailsService.getMicrSiteDetails(
					this.location,
					this.searchTerm,
					this.micrositeId
				);
			}

			let micrositeFromServer = this.result[0].micrositeDetails;
			//console.log(this.result);
			micrositeFromServer.forEach((element) => {
				const companyDetails: MicrositeDetail = {
					companyID: element.companyID,
					micrositeID: element.micrositeID,
					company: element.company,
					micrositeCustomUrl: element.micrositeCustomUrl,
					companyLogoImage: 'http://' + element.companyLogoImage,
					isPreferred: element.isPreferred,
					fullAddress: element.fullAddress,
					streetAddress: element.streetAddress,
					city: element.city,
					state: element.state,
					zip: element.zip,
					longitude: element.longitude,
					latitude: element.latitude,
					phone: element.phone,
					todaysHours: element.todaysHours,
					isOpen: element.isOpen,
					companyDescription: element.companyDescription,
					companyLinks: element.companyLinks.map((link) => ({
						id: link.id,
						linkType: link.linkType,
						label: link.label,
						url: link.url,
					})),
					hoursOperation: element.hoursOperation.map((time) => ({
						weekdayName: time.weekdayName,
						openTime: time.openTime,
						closeTime: time.closeTime,
						isClosed: time.isClosed,
					})),
					otherLocations: element.otherLocations.map((location) => ({
						sortOrder: location.sortOrder,
						description: location.description,
						fullAddress: location.fullAddress,
						streetAddress: location.streetAddress,
						city: location.city,
						state: location.state,
						zip: location.zip,
						longitude: location.longitude,
						latitude: location.latitude,
						phone: location.phone,
					})),
					photos: element.photos.map((img) => ({
						photoImage: 'http://' + img.photoImage,
					})),
					videos: element.videos.map((vid) => ({
						id: vid.id,
						url: 'http://' + vid.url,
					})),
					printAds: element.printAds.map((ad) => ({
						printAdImage: 'http://' + ad.printAdImage,
					})),
					businessInfo: element.businessInfo,
					headings: element.headings,
				};
				this.latitude = element.latitude;
				this.longitude = element.longitude;
				this._imageAlbums = element.photos.map((img) => ({
					src: 'http://' + img.photoImage,
					thumb: 'http://' + img.photoImage,
				}));
				this._adAlbums = element.printAds.map((ad) => ({
					src: 'http://' + ad.printAdImage,
					thumb: 'http://' + ad.printAdImage,
				}));
				this.companyDetails.push(companyDetails);
			});
			//console.log(this.companyDetails);
			this.relatedCategories = [];
			let relatedCategoriesFromSever = this.result[0].relatedCategories;
			relatedCategoriesFromSever.forEach((element) => {
				this.relatedCategories.push({
					title: element.title,
					searchTerm: element.searchTerm,
				});
			});

			this.preferredBusinesses = [];
			let preferredBusinessesFromServer = this.result[0].preferredBusinesses;
			preferredBusinessesFromServer.forEach((element) => {
				const preferredBusinesses: any = {
					city: element.city,
					company: element.company,
					companyDescription: element.companyDescription,
					companyID: element.companyID,
					companyLogoImage: 'http://' + element.companyLogoImage,
					companySortOrder: element.companySortOrder,
					fullAddress: element.fullAddress,
					isPreferred: element.isPreferred,
					latitude: element.latitude,
					longitude: element.longitude,
					micrositeID: element.micrositeID,
					phone: element.phone,
					state: element.state,
					streetAddress: element.streetAddress,
					yellowHighlight: element.yellowHighlight,
					zip: element.zip,
				};
				this.preferredBusinesses.push(preferredBusinesses);
			});

			this.quickLinks = [];
			let quickLinksFromServer = this.result[0].quickLinks;
			quickLinksFromServer.forEach((element) => {
				this.quickLinks.push({
					groupName: element.groupName,
					title: element.title,
					url: this.getFullUrl(element.url),
				});
			});

			// Set SEO metadata for the microsite
			this.setMetaTags(micrositeFromServer[0]);

			// console.log('microsites', this.result);
			// console.log('related categories', this.relatedCategories);
			// console.log('preferred businesses', this.preferredBusinesses);
		} catch (error) {
			console.error('Error fetching microsite details', error);
		} finally {
			this.spinner.hide();
		}
	}
	pageTitleContent = [
		{
			title: 'Find Popular Places',
		},
	];

	onSearchTermChanged(term: string) {
		this.searchTerm = term;
	}

	setSearchTerm(searchTerm: string) {
		this.searchTerm = searchTerm;
		this.searchTermSelected.emit(this.searchTerm);
		this.router.navigate([
			'search-list-view/directory',
			this.directory,
			'location',
			this.location,
			'search',
			this.searchTerm,
		]);
	}

	onLocationChanged(location: string) {
		this.location = location;
	}

	getEmbedUrl(url: string): string {
		const videoIdMatch = url.match(/(?:youtube\.com\/watch\?v=|youtu\.be\/)([^&]+)/);
		if (videoIdMatch && videoIdMatch[1]) {
			return `https://www.youtube.com/embed/${videoIdMatch[1]}`;
		}
		return url; // Return the original URL if it's not a YouTube link
	}

	getSafeEmbedUrl(url: string): SafeResourceUrl {
		const embedUrl = this.getEmbedUrl(url);
		return this.sanitizer.bypassSecurityTrustResourceUrl(embedUrl);
	}

	getFullUrl(url: string): string {
		if (!url) {
			return ''; // Return empty string or handle missing URLs as needed
		}
		return url.startsWith('http') ? url : 'http://' + url;
	}

	setMicrosite(micrositeId: string) {
		this.micrositeId = micrositeId;
		this.companyDetails = [];
		this.getMicrositeDetails();
	}

	// Method to set dynamic meta tags
	setMetaTags(microsite: MicrositeDetail) {
		const title = `Microsite Page - ${microsite.company} in ${this.directory}`;
		this.title.setTitle(title);
		this.meta.updateTag({ name: 'description', content: microsite.companyDescription });
		this.meta.updateTag({ name: 'keywords', content: `microsite, ${microsite.company}, ${this.searchTerm}` });

		const canonicalUrl = `https://www.arvigpages.com/microsite/${this.micrositeId}?searchTerm=${this.searchTerm}&directory=${this.directory}&location=${this.location}`;
		this.meta.updateTag({ rel: 'canonical', href: canonicalUrl });

		const structuredData = {
			'@context': 'https://schema.org',
			'@type': 'Organization',
			name: microsite.company,
			url: microsite.micrositeCustomUrl,
			logo: microsite.companyLogoImage,
			description: microsite.companyDescription,
		};
		this.meta.addTag({ type: 'application/ld+json', content: JSON.stringify(structuredData) });
	}
}
