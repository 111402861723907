import { Component } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Component({
	selector: 'app-terms-and-conditions',
	templateUrl: './terms-and-conditions.component.html',
	styleUrls: ['./terms-and-conditions.component.scss'],
})
export class TermsAndConditionsComponent {
	constructor(
		private meta: Meta,
		private title: Title
	) {
		this.setSEOData();
	}

	private setSEOData() {
		this.title.setTitle('Terms and Conditions - Arvig Pages');
		this.meta.addTags([
			{ name: 'description', content: 'Read the terms and conditions for using Arvig Pages services and website.' },
			{ name: 'keywords', content: 'Terms and Conditions, Arvig Pages, user agreement' },
		]);
		this.meta.addTag({ rel: 'canonical', href: 'https://www.arvigpages.com/terms-and-conditions' });
	}
}
