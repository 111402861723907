import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'app-coming-soon',
	templateUrl: './coming-soon.component.html',
	styleUrls: ['./coming-soon.component.scss'],
})
export class ComingSoonComponent implements OnInit {
	days: any;
	hours: any;
	minutes: any;
	seconds: any;
	myInterval: any;

	constructor() {}

	ngOnInit() {}
}
