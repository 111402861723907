<app-navbar-style-one [directory]="directory"></app-navbar-style-one>

<app-homeone-banner [directory]="directory"></app-homeone-banner>

<app-feature-ads [featureAds]="featureAds"></app-feature-ads>

<app-advertise-with-us [directory]="directory"></app-advertise-with-us>

<section>
	<app-category [categories]="categories" [directory]="directory"></app-category>
</section>

<section>
	<app-city [cities]="cities" [directory]="directory"></app-city>
</section>

<!-- <app-app-download [mobileAppSection]="mobileAppSection"></app-app-download> -->

<app-footer-style-one [quickLinks]="quickLinks" [directory]="directory"></app-footer-style-one>
