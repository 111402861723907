import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';

@Component({
	selector: 'app-pagination',
	templateUrl: './pagination.component.html',
	styleUrls: ['./pagination.component.scss'],
})
export class PaginationComponent implements OnChanges {
	@Input() currentPage: number;
	@Input() itemsPerPage: number;
	@Input() totalItems: number;

	@Output() pageChanged = new EventEmitter<number>();
	pageNumbers: number[] = [];

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.totalItems || changes.itemsPerPage || changes.currentPage) {
			this.pageNumbers = this.getPageNumbers();
		}
	}

	nextPage() {
		if (this.currentPage < this.getTotalPages()) {
			this.pageChanged.emit(this.currentPage + 1);
		}
	}

	prevPage() {
		if (this.currentPage > 1) {
			this.pageChanged.emit(this.currentPage - 1);
		}
	}

	setPage(pageNumber: number) {
		if (pageNumber >= 1 && pageNumber <= this.getTotalPages()) {
			this.pageChanged.emit(pageNumber);
		}
	}

	getTotalPages(): number {
		return Math.ceil(this.totalItems / this.itemsPerPage);
	}

	getPageNumbers(): number[] {
		const totalPages = this.getTotalPages();
		const pageNumbers: number[] = [];
		const maxPagesToShow = 5;

		if (totalPages <= maxPagesToShow) {
			// If total pages are less than or equal to maxPagesToShow, show all pages
			return Array.from({ length: totalPages }, (_, i) => i + 1);
		}

		// Always show the first page
		pageNumbers.push(1);

		const startPage = Math.max(2, this.currentPage - 1);
		const endPage = Math.min(totalPages - 1, this.currentPage + 1);

		if (startPage > 2) {
			pageNumbers.push(-1); // Placeholder for ellipsis
		}

		for (let i = startPage; i <= endPage; i++) {
			pageNumbers.push(i);
		}

		if (endPage < totalPages - 1) {
			pageNumbers.push(-1); // Placeholder for ellipsis
		}

		// Always show the last page
		pageNumbers.push(totalPages);

		return pageNumbers;
	}
}
