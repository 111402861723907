import { Component } from '@angular/core';
import { Router, NavigationCancel, NavigationEnd } from '@angular/router';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { filter } from 'rxjs/operators';
import { Meta } from '@angular/platform-browser';
declare let $: any;

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
	providers: [
		Location,
		{
			provide: LocationStrategy,
			useClass: PathLocationStrategy,
		},
	],
})
export class AppComponent {
	title = 'Arvig Pages: Home';
	location: any;
	routerSubscription: any;

	constructor(
		private router: Router,
		private metaTagService: Meta
	) {}

	ngOnInit() {
		this.addStructuredData();
		this.metaTagService.addTags([
			{
				name: 'keywords',
				content:
					'advertising platform, online advertising, digital marketing, ad campaigns, media buying, ad management, ad network, online ads, target audience, marketing automation, Arvig,',
			},
			{
				name: 'description',
				content:
					'This is a local business directory. Learn how to find the best local businesses near you using Arvig Pages, with personalized search results based on categories and locations.',
			},
			{ name: 'robots', content: 'index, follow' },
			{ name: 'author', content: 'ArvigPages' },
			{ name: 'viewport', content: 'width=device-width, initial-scale=1' },
			{ name: 'date', content: '2024-09-24', scheme: 'YYYY-MM-DD' },
			{ charset: 'UTF-8' },
		]);
		// Social Media Meta Tags (Open Graph and Twitter)
		this.metaTagService.addTags([
			// Open Graph Meta Tags for Facebook
			{ property: 'og:title', content: 'Arvig Pages ' },
			{
				property: 'og:description',
				content:
					'Find the best local businesses near you with Arvig Pages. Search by categories, locations, and more for a personalized user experience.',
			},
			{ property: 'og:url', content: 'https://www.arvigpages.com/' },
			{ property: 'og:type', content: 'website' },

			// Twitter Meta Tags
			{ name: 'twitter:title', content: 'Arvig Pages ' },
			{
				name: 'twitter:description',
				content:
					'Discover the best local businesses with Arvig Pages. Search by category and location for a seamless experience.',
			},
			{ name: 'twitter:card', content: 'summary_large_image' },
			{ name: 'twitter:url', content: 'https://arvigpages.com/' },
		]);
		this.recallJsFuntions();
	}

	recallJsFuntions() {
		this.routerSubscription = this.router.events
			.pipe(filter((event) => event instanceof NavigationEnd || event instanceof NavigationCancel))
			.subscribe((event) => {
				this.location = this.router.url;
				if (!(event instanceof NavigationEnd)) {
					return;
				}
				if (typeof window !== 'undefined') {
					window.scrollTo(0, 0);
				}
			});
	}

	addStructuredData() {
		const structuredData = {
			'@context': 'https://schema.org',
			'@type': 'LocalBusiness',
			name: 'Arvig Pages',
			url: 'https://www.arvigpages.com/',
			description:
				'This is a local business directory. Learn how to find the best local businesses near you using Arvig Pages, with personalized search results based on categories and locations.',
		};

		// Add the structured data as a script tag
		if (typeof document !== 'undefined') {
			const script = document.createElement('script');
			script.type = 'application/ld+json';
			script.innerHTML = JSON.stringify(structuredData);
			document.head.appendChild(script);
		}
	}
}
