import { Component, OnInit, HostListener, Input } from '@angular/core';
import { HeaderFooterService } from '../../core/services/header-footer/header-footer.service';
import { DirectoryHomeModel } from '../../core/models/directoryHome/directory.home.model';
import { HeaderFooterModel, HeaderLink } from '../../core/models/headerFooter/header.footer.model';

@Component({
	selector: 'app-navbar-style-one',
	templateUrl: './navbar-style-one.component.html',
	styleUrls: ['./navbar-style-one.component.scss'],
})
export class NavbarStyleOneComponent implements OnInit {
	@Input() directory: string = '';
	headerAndFooter: HeaderFooterModel = null;
	header: HeaderLink[] = [];
	logo: string = '';

	constructor(private headerFooterService: HeaderFooterService) {}

	ngOnInit(): void {
		this.getHeader();
	}

	async getHeader() {
		try {
			this.headerAndFooter = await this.headerFooterService.getHeaderAndFooter(this.directory);
			//	console.log('HeaderFooter', this.headerAndFooter);

			let headerFromServer = this.headerAndFooter[0].headerLinks;
			this.logo = 'http://' + this.headerAndFooter[0].headerLogoImage;

			headerFromServer.forEach((element) => {
				this.header.push({
					title: element.title,
					url: 'http://' + element.url,
					subHeaderLinks: element.subHeaderLinks,
				});
			});
		} catch (error) {}
	}

	classApplied = false;
	toggleClass() {
		this.classApplied = !this.classApplied;
	}

	// Navbar Sticky
	isSticky: boolean = false;
	@HostListener('window:scroll', ['$event'])
	checkScroll() {
		if (typeof document !== 'undefined') {
			if (typeof window !== 'undefined') {
				const scrollPosition = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
				if (scrollPosition >= 50) {
					this.isSticky = true;
				} else {
					this.isSticky = false;
				}
			}
		}
	}

	// Signin/Signup Popup
	isSigninSignupOpen = false;
	openSigninSignupPopup(): void {
		this.isSigninSignupOpen = true;
	}
	closeSigninSignupPopup(): void {
		this.isSigninSignupOpen = false;
	}

	// Signin/Signup Tabs
	currentTab = 'tab1';
	switchTab(event: MouseEvent, tab: string) {
		event.preventDefault();
		this.currentTab = tab;
	}
}
