import { Component, Input, OnInit } from '@angular/core';
import { CityModel } from '../../core/models/city/city.model';
import { Router } from '@angular/router';

@Component({
	selector: 'app-city',
	templateUrl: './city.component.html',
	styleUrl: './city.component.scss',
})
export class CityComponent implements OnInit {
	@Input() directory: string = '';
	@Input() cities: CityModel[] = [];
	selectedLocation: string = 'Everywhere';

	/**
	 *
	 */
	constructor(private router: Router) {}

	ngOnInit(): void {}

	setCityNameAsLocation(location: string) {
		this.selectedLocation = location;
		this.navigateLocationHome();
	}

	navigateLocationHome() {
		this.router.navigate(['location-home/directory', this.directory, 'location', this.selectedLocation]);
	}
}
