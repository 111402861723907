<!-- Start Category Area -->
<section class="pt-70">
	<div class="container">
		<div class="section-title">
			<h2>City Guide</h2>
		</div>
		<div class="row">
			<div class="col-lg-2 col-sm-6 col-md-4" *ngFor="let Content of cities">
				<div class="single-category-box">
					<!-- <div class="icon">
          <i class="{{ Content.icon }}"></i>
        </div> -->
					<h3>{{ Content.cityName }}</h3>
					<!-- <span>{{ Content.numberOfPlaces }}</span> -->
					<a (click)="setCityNameAsLocation(Content.cityName)" class="link-btn"></a>
				</div>
			</div>
		</div>
	</div>
</section>
<!-- End Category Area -->
