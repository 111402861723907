import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { NgxSpinnerService } from 'ngx-spinner';
import { DirectoryHomeModel } from 'src/app/components/core/models/directoryHome/directory.home.model';
import { FeaturedAdModel } from 'src/app/components/core/models/featureAd/feature.ad.model';
import { DirectoryHomeService } from 'src/app/components/core/services/directory-home/directory-home.service';

@Component({
	selector: 'app-feature-ads',
	templateUrl: './feature.ads.component.html',
	styleUrls: ['./feature.ads.component.scss'],
})
export class FeatureAdsComponent implements OnInit {
	@Input() featureAds: FeaturedAdModel[] = [];

	constructor(
		private directoryHomeService: DirectoryHomeService,
		private sanitizer: DomSanitizer,
		private spinner: NgxSpinnerService
	) {}

	ngOnInit(): void {}

	sectionTitle = [
		{
			title: 'Featured Businesses',
		},
	];

	customOptions: OwlOptions = {
		loop: true, // Enable looping
		nav: true,
		dots: true,
		autoplayHoverPause: true,
		autoplay: true,
		margin: 30,
		mouseDrag: true, // Ensure mouse drag is enabled
		touchDrag: true, // Ensure touch drag is enabled
		navText: ["<i class='flaticon-left-chevron'></i>", "<i class='flaticon-right-chevron'></i>"],
		responsive: {
			0: {
				items: 1,
			},
			568: {
				items: 2,
			},
			768: {
				items: 3,
			},
			1200: {
				items: 4,
			},
		},
	};

	customOptions2: OwlOptions = {
		loop: true,
		nav: true,
		dots: false,
		animateOut: 'fadeOut',
		animateIn: 'fadeIn',
		autoplayHoverPause: true,
		autoplay: true,
		mouseDrag: false,
		items: 1,
		navText: ["<i class='flaticon-left-chevron'></i>", "<i class='flaticon-right-chevron'></i>"],
	};
}
