import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CategoriesComponent } from './components/pages/categories/categories.component';
import { ComingSoonComponent } from './components/pages/coming-soon/coming-soon.component';
import { FaqComponent } from './components/pages/faq/faq.component';
import { SearchListViewComponent } from './components/pages/search-list-view/search.list.view.component';
import { MapListViewComponent } from './components/pages/map-list-view/map.list.view.component';
import { HomeDemoOneComponent } from './components/pages/home/home.component';
import { NotFoundComponent } from './components/pages/not-found/not-found.component';
import { MicrositeComponent } from './components/pages/microsite/microsite.component';
import { LandingComponent } from './components/pages/landing/landing.component';
import { LocationHomeComponent } from './components/pages/location-home/location-home.component';
import { AdvertiseWithUsCommonComponent } from './components/pages/advertise-with-us-common/advertise-with-us-common.component';
import { ContactUsComponent } from './components/pages/contact-us/contact-us.component';
import { TermsAndConditionsComponent } from './components/pages/terms-and-conditions/terms-and-conditions.component';

const routes: Routes = [
	{ path: '', component: LandingComponent },
	{ path: 'home/directory/:directory', component: HomeDemoOneComponent },
	{ path: 'faq', component: FaqComponent },
	{ path: 'coming-soon', component: ComingSoonComponent },
	{ path: 'categories', component: CategoriesComponent },
	{
		path: 'microsite/:id/directory/:directory/location/:location/search/:searchTerm',
		component: MicrositeComponent,
	},
	{
		path: 'map-list-view/directory/:directory/location/:location/search/:searchTerm',
		component: MapListViewComponent,
	},
	{
		path: 'search-list-view/directory/:directory/location/:location/search/:searchTerm',
		component: SearchListViewComponent,
	},
	{ path: 'location-home/directory/:directory/location/:location', component: LocationHomeComponent },

	{ path: 'advertise-with-us', component: AdvertiseWithUsCommonComponent },

	{ path: 'contact-us', component: ContactUsComponent },

	{ path: 'terms-and-conditions', component: TermsAndConditionsComponent },
	// Here add new pages component

	{ path: '**', component: NotFoundComponent },
];

@NgModule({
	imports: [RouterModule.forRoot(routes, { useHash: false })],
	exports: [RouterModule],
})
export class AppRoutingModule {}
