import { Component, Input, OnInit } from '@angular/core';
import { State } from 'src/app/components/core/models/landing/landing.model';

@Component({
	selector: 'app-landing-banner',
	templateUrl: './landing-banner.component.html',
	styleUrl: './landing-banner.component.scss',
})
export class LandingBannerComponent implements OnInit {
	@Input() states: State[] = [];
	constructor() {}
	ngOnInit(): void {}
	scrollTo(stateName: string) {
		if (typeof document !== 'undefined') {
			const element = document.getElementById(stateName);
			if (element) {
				const navBarHeight = document.querySelector('nav')?.clientHeight || 0; // Replace 'nav' with the appropriate selector for your navigation bar
				const elementPosition = element.getBoundingClientRect().top + window.pageYOffset;
				const offsetPosition = elementPosition - navBarHeight - 20; // Adjust the -20 to set additional offset if needed
				if (typeof window !== 'undefined') {
					window.scrollTo({
						top: offsetPosition,
						behavior: 'smooth',
					});
				}
			}
		}
	}
}
