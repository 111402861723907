import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { MicrositeDataModel } from '../../models/microSite/micro.site.model';
import { firstValueFrom } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class MicrositeService {
	baseUrl = environment.ApiUrl;
	constructor(private _httpClient: HttpClient) {}
	/**
	 * @method {GET}
	 * @param {string,string,string}
	 * @route  'microsite?searchTerm&micrositeID&locationName'
	 * @returns {Promise<MicrositeDataModel>}
	 * @description get microsite details results
	 */

	async getMicrSiteDetails(locationName: string, searchTerm: string, micrositeID: string): Promise<MicrositeDataModel> {
		const params = { locationName, searchTerm, micrositeID };
		try {
			return await firstValueFrom(this._httpClient.get<MicrositeDataModel>(`${this.baseUrl}microsite`, { params }));
		} catch (error) {}
	}
}
