import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HeaderFooterModel } from '../../models/headerFooter/header.footer.model';
import { firstValueFrom } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class HeaderFooterService {
	baseUrl = environment.ApiUrl;
	constructor(private _httpClient: HttpClient) {}

	/**
	 * @method {GET}
	 * @param {string}
	 * @route  headerFooter?directory'
	 * @returns {Promise<HeaderFooterModel>}
	 * @description get header and footer details list
	 */
	async getHeaderAndFooter(directory: String): Promise<HeaderFooterModel> {
		try {
			return await firstValueFrom(
				this._httpClient.get<HeaderFooterModel>(`${this.baseUrl}headerFooter?directory=${directory}`)
			);
		} catch (error) {}
	}
}
