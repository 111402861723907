import { Component, OnInit } from '@angular/core';
import { LandingService } from '../../core/services/landing/landing.service';
import { LandingModel, State } from '../../core/models/landing/landing.model';
import { DirectoryModel } from '../../core/models/directory/directory.model';
import { FeaturedAdModel } from '../../core/models/featureAd/feature.ad.model';
import { MobileAppSectionModel } from '../../core/models/mobileAppSection/mobile.app.section.model';
import { QuickLinkModel } from '../../core/models/quickLink/quick.link.model';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { Meta, Title } from '@angular/platform-browser';

@Component({
	selector: 'app-landing',
	templateUrl: './landing.component.html',
	styleUrls: ['./landing.component.scss'],
})
export class LandingComponent implements OnInit {
	response: LandingModel;
	states: State[] = [];
	directories: DirectoryModel[] = [];
	featureAds: FeaturedAdModel[] = [];
	mobileAppSection: MobileAppSectionModel[] = [];
	quickLinks: QuickLinkModel[] = [];
	selectedDirectory: string = '';
	currentSection: string = '';
	selectedLocation: string = 'Everywhere';

	constructor(
		private landingService: LandingService,
		private sanitizer: DomSanitizer,
		private router: Router,
		private spinner: NgxSpinnerService,
		private gtmService: GoogleTagManagerService,
		private meta: Meta,
		private title: Title // Angular's Title service
	) {}

	ngOnInit(): void {
		this.getLandingData();
		this.addSchemaMarkup();
		this.setSEOData(); // Set SEO metadata

		const gtmTag = {
			event: 'pageView',
			pageName: 'Landing Page',
		};

		this.gtmService.pushTag(gtmTag);
	}

	// Set SEO metadata using Angular's Meta and Title services
	private setSEOData() {
		this.title.setTitle('Arvig Pages');
		this.meta.updateTag({
			name: 'description',
			content:
				'Explore top local businesses in various categories and states. Easily search by city or location on Arvig Pages for a seamless user experience.',
		});
		this.meta.updateTag({
			name: 'keywords',
			content:
				'local businesses, directory listing, search local businesses, best businesses near me, find businesses by city, advertising services',
		});
		this.meta.updateTag({
			property: 'og:image',
			content: 'https://iypapi.arvigpages.com/data/OrganizationImages/ArvigLogoSmallWhite.png',
		});
		this.meta.updateTag({ rel: 'canonical', href: 'https://www.arvigpages.com/' });
	}

	// Add structured data for SEO
	addSchemaMarkup() {
		const structuredData = {
			'@context': 'https://schema.org',
			'@type': 'WebPage',
			name: 'Arvig Pages: Local Business Directories',
			description: 'Find and explore the best local businesses in various categories and regions on Arvig Pages.',
			publisher: {
				'@type': 'Organization',
				name: 'ArvigPages',
			},
			mainEntity: {
				'@type': 'ItemList',
				itemListElement: this.directories.map((directory, index) => ({
					'@type': 'ListItem',
					position: index + 1,
					name: directory.directoryName,
					url: directory.directoryHomeUrl,
				})),
			},
		};
		if (typeof document !== 'undefined') {
			const script = document.createElement('script');
			script.type = 'application/ld+json';
			script.text = JSON.stringify(structuredData);
			document.head.appendChild(script);
		}
	}

	async getLandingData() {
		try {
			this.spinner.show();
			this.response = await this.landingService.getLandingDetails();

			let statesFromServer = this.response[0].states;
			statesFromServer.forEach((element) => {
				const states: State = {
					stateName: element.stateName,
				};
				this.states.push(states);
			});

			let featureAdsFromServer = this.response[0].featuredAds;
			for (let index = 0; index < featureAdsFromServer.length; index++) {
				let ad: FeaturedAdModel = new FeaturedAdModel();
				ad.imageURL = this.sanitizer.bypassSecurityTrustUrl(
					'http://' + featureAdsFromServer[index].featuredAdImage
				) as string;

				ad.adClickURL = 'http://' + featureAdsFromServer[index].adClickURL;
				ad.company = featureAdsFromServer[index].company;
				ad.featuredAdImage = featureAdsFromServer[index].featuredAdImage;

				this.featureAds.push(ad);
			}

			let directoriesFromServer = this.response[0].directories;
			directoriesFromServer.forEach((element) => {
				const directories: DirectoryModel = {
					stateName: element.stateName,
					directoryName: element.directoryName,
					directoryHomeUrl: element.directoryHomeUrl,
					directoryCoverImage: 'http://' + element.directoryCoverImage,
					cities: element.cities.map((city) => ({
						cityName: city.city,
						locationHomeUrl: city.locationHomeUrl,
					})),
				};

				this.directories.push(directories);
			});

			let quickLinksFromServer = this.response[0].quickLinks;
			quickLinksFromServer.forEach((element) => {
				this.quickLinks.push({
					groupName: element.groupName,
					title: element.title,
					url: this.getFullUrl(element.url),
				});
			});

			let mobileAppSectionFromServer = this.response[0].mobileAppSection;
			mobileAppSectionFromServer.forEach((element) => {
				this.mobileAppSection.push({
					mobileAppImage: element.mobileAppImage,
					appStoreUrl: element.appStoreUrl,
					googlePlayStoreUrl: element.googlePlayStoreUrl,
				});
			});
		} catch (error) {
			//console.error('Error fetching landing data', error);
		} finally {
			this.spinner.hide();
		}
	}

	sectionTitle = [
		{
			title: 'Local Business Directories',
		},
	];

	getDirectoriesByState(stateName: string): DirectoryModel[] {
		return this.directories.filter((directory) => directory.stateName === stateName);
	}

	navigateHome(directory: string) {
		this.selectedDirectory = directory;
		this.router.navigate(['home/directory', this.selectedDirectory]);
	}

	scrollTo(section: string) {
		document.getElementById(section)?.scrollIntoView({ behavior: 'smooth' });
	}

	onSectionChange(sectionId) {
		this.currentSection = sectionId;
	}

	setCityNameAsLocation(directory: string, location: string) {
		this.selectedLocation = location;
		this.selectedDirectory = directory;
		this.navigateLocationHome();
	}

	navigateLocationHome() {
		this.router.navigate(['location-home/directory', this.selectedDirectory, 'location', this.selectedLocation]);
	}

	getFullUrl(url: string): string {
		if (!url) {
			return ''; // Return empty string or handle missing URLs as needed
		}
		return url.startsWith('http') ? url : 'http://' + url;
	}
}
