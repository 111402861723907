<div class="main-banner-area">
	<div class="container">
		<div class="main-banner-content">
			<h1>
				Find Nearby
				<ngx-typed-js
					[strings]="['Hotels', 'Restaurants', 'Beauty', 'Fitness', 'Shopping']"
					[typeSpeed]="100"
					[loop]="true"
					[backSpeed]="100"
					[showCursor]="false"
				>
					<span class="typing"></span>
				</ngx-typed-js>
			</h1>
			<form class="align-items-center p-2">
				<div class="row m-0 align-items-center">
					<div class="col-lg-7 col-md-12 p-0">
						<div class="form-group">
							<label><i class="flaticon-search"></i></label>
							<input
								type="text"
								class="form-control"
								(input)="onSearchTermInput($event)"
								placeholder="What are you looking for?"
								[(ngModel)]="searchTerm"
								[ngModelOptions]="{ standalone: true }"
							/>

							<!-- Auto-complete dropdown -->
							<ul *ngIf="autoList.length > 0" class="auto-complete-list">
								<li *ngFor="let item of autoList" (click)="selectAutoCompleteItem(item)">
									{{ item.searchTermMatch }}
								</li>
							</ul>
						</div>
					</div>

					<div class="col-lg-3 col-md-12 p-0">
						<div class="form-group category-select">
							<label><i class="flaticon-pin"></i></label>
							<ngx-select-dropdown
								(change)="onLocationChanged($event)"
								name="category-select"
								tabindex="0"
								[multiple]="false"
								[(ngModel)]="selectedLocation"
								[config]="config"
								[options]="options"
								[ngModelOptions]="{ standalone: true }"
							>
							</ngx-select-dropdown>
						</div>
					</div>
					<div class="col-lg-2 col-md-12 p-0">
						<div class="submit-btn">
							<button (click)="navigateSearchList()" type="submit" style="color: #1d1060">Search Now</button>
						</div>
					</div>
				</div>
			</form>
		</div>
		<ul class="popular-search-list">
			<li>Popular:</li>
			<li *ngFor="let List of popularHeadings">
				<a (click)="setPopularSearchTerm(List.searchTerm)">{{ List.label }}</a>
			</li>
		</ul>
	</div>
</div>
