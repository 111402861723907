import { Component, NgZone, OnInit } from '@angular/core';
import { AutoCompleteService } from '../../core/services/auto-complete/auto-complete.service';
import { CategoryService } from '../../core/services/category/category.service';
import { DirectoryHomeService } from '../../core/services/directory-home/directory-home.service';
import { HeaderFooterService } from '../../core/services/header-footer/header-footer.service';
import { LandingService } from '../../core/services/landing/landing.service';
import { SearchResultService } from '../../core/services/search-result/search-result.service';
import { LocationHomeService } from '../../core/services/location-home/location-home.service';
import { ActivatedRoute } from '@angular/router';
import { DirectoryHomeModel } from '../../core/models/directoryHome/directory.home.model';
import { FeaturedAdModel } from '../../core/models/featureAd/feature.ad.model';
import { DomSanitizer } from '@angular/platform-browser';
import { QuickLinkModel } from '../../core/models/quickLink/quick.link.model';
import { MobileAppSectionModel } from '../../core/models/mobileAppSection/mobile.app.section.model';
import { CityModel } from '../../core/models/city/city.model';
import { Meta, Title } from '@angular/platform-browser';

@Component({
	selector: 'app-home-demo-one',
	templateUrl: './home.component.html',
	styleUrls: ['./home.component.scss'],
})
export class HomeDemoOneComponent implements OnInit {
	response: DirectoryHomeModel;
	featureAds: FeaturedAdModel[] = [];
	categories: any[] = [];
	searchTerm: string = '';
	directory: string = '';
	cities: CityModel[] = [];

	quickLinks: QuickLinkModel[] = [];
	mobileAppSection: MobileAppSectionModel[] = [];

	constructor(
		private directoryHomeService: DirectoryHomeService,
		private headerFooterService: HeaderFooterService,
		private sanitizer: DomSanitizer,
		private route: ActivatedRoute,
		private ngZone: NgZone,
		private meta: Meta,
		private title: Title
	) {}

	ngOnInit(): void {
		this.directory = this.route.snapshot.paramMap.get('directory');
		this.updatePageMetadata();

		this.loadData();
		this.scrollToTop();
		this.addStructuredData();
		this.addBreadcrumbStructuredData();
	}

	public setTitle(newTitle: string) {
		this.title.setTitle(newTitle);
		this.meta.updateTag({ name: 'title', content: newTitle });
	}

	updatePageMetadata() {
		this.setTitle(`Discover ${this.directory} Services | Arvig Pages`);
		this.meta.updateTag({
			name: 'description',
			content: `Explore ${this.directory} services and local businesses with Arvig Pages. Find top services and resources tailored to your needs.`,
		});
		this.meta.updateTag({ property: 'og:title', content: `Discover ${this.directory} on Arvig Pages` });
		this.meta.updateTag({
			property: 'og:description',
			content: `Find local businesses and services in ${this.directory}.`,
		});
		this.meta.updateTag({ property: 'og:url', content: window.location.href });
		this.meta.updateTag({ property: 'og:image', content: 'https://www.arvigpages.com/assets/og-image.jpg' });
		this.updateCanonicalUrl();
	}

	updateCanonicalUrl() {
		const canonicalUrl = this.directory
			? `https://www.arvigpages.com/home/directory/${this.directory}`
			: 'https://www.arvigpages.com/home';

		let link: HTMLLinkElement | null = document.querySelector('link[rel="canonical"]');

		if (!link) {
			link = document.createElement('link');
			link.setAttribute('rel', 'canonical');
			document.head.appendChild(link);
		}

		link.setAttribute('href', canonicalUrl);
	}

	scrollToTop() {
		this.ngZone.runOutsideAngular(() => {
			setTimeout(() => {
				if (typeof window !== 'undefined') {
					window.scrollTo(0, 0);
				}
			}, 0);
		});
	}

	async loadData() {
		try {
			this.response = await this.directoryHomeService.getDirectoryHomeResults(this.directory);
			//console.log('Directory Home Results:', this.response);

			let featureAdsFromSever = this.response[0].featuredAds;
			//console.log('FeatureAds', featureAdsFromSever);

			for (let index = 0; index < featureAdsFromSever.length; index++) {
				let ad: FeaturedAdModel = new FeaturedAdModel();
				ad.imageURL = this.sanitizer.bypassSecurityTrustUrl(
					'http://' + featureAdsFromSever[index].featuredAdImage
				) as string;

				ad.adClickURL = 'http://' + featureAdsFromSever[index].adClickURL;
				ad.company = featureAdsFromSever[index].company;
				ad.featuredAdImage = featureAdsFromSever[index].featuredAdImage;

				this.featureAds.push(ad);
			}

			this.response[0].categories.forEach((element, index) => {
				this.categories.push({
					title: element.title,
					searchTerm: element.searchTerm,
					categoryIcon: 'http://' + element.categoryIcon,
				});
			});

			this.categories.push({
				categoryIcon: 'assets/fonts/more-categories.svg',
				title: 'More Categories',
				searchTerm: 'More Categories',
			});

			let quickLinksFromServer = this.response[0].quickLinks;
			quickLinksFromServer.forEach((element) => {
				this.quickLinks.push({
					groupName: element.groupName,
					title: element.title,
					url: this.getFullUrl(element.url),
				});
			});

			let mobileAppSectionFromServer = this.response[0].mobileAppSection;
			mobileAppSectionFromServer.forEach((element) => {
				this.mobileAppSection.push({
					mobileAppImage: element.mobileAppImage,
					appStoreUrl: element.appStoreUrl,
					googlePlayStoreUrl: element.googlePlayStoreUrl,
				});
			});

			let citiesFromServer = this.response[0].cities;
			citiesFromServer.forEach((element) => {
				this.cities.push({
					cityName: element.cityName,
					locationHomeUrl: element.locationHomeUrl,
				});
			});

			const headerFooter = await this.headerFooterService.getHeaderAndFooter(this.directory);
			//console.log('Header and Footer:', headerFooter);
		} catch (error) {
			console.error('Error loading data:', error);
		}
	}

	getFullUrl(url: string): string {
		if (!url) {
			return ''; // Return empty string or handle missing URLs as needed
		}
		return url.startsWith('http') ? url : 'http://' + url;
	}

	addStructuredData() {
		const schema = {
			'@context': 'https://schema.org',
			'@type': 'WebPage',
			name: this.directory || 'Arvig Pages Home',
			description: 'Discover local services and businesses on Arvig Pages.',
			url: window.location.href,
			potentialAction: {
				'@type': 'SearchAction',
				target: `https://www.arvigpages.com/search?query={search_term_string}`,
				'query-input': 'required name=search_term_string',
			},
		};
		const script = document.createElement('script');
		script.type = 'application/ld+json';
		script.text = JSON.stringify(schema);
		document.head.appendChild(script);
	}

	addBreadcrumbStructuredData() {
		const breadcrumbSchema = {
			'@context': 'https://schema.org',
			'@type': 'BreadcrumbList',
			itemListElement: [
				{
					'@type': 'ListItem',
					position: 1,
					name: 'Home',
					item: 'https://www.arvigpages.com/',
				},
				{
					'@type': 'ListItem',
					position: 2,
					name: this.directory,
					item: `https://www.arvigpages.com/home/directory/${this.directory}`,
				},
			],
		};
		const script = document.createElement('script');
		script.type = 'application/ld+json';
		script.text = JSON.stringify(breadcrumbSchema);
		document.head.appendChild(script);
	}
}
