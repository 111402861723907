import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { AdvertiseModel, AdvertiseState } from '../../core/models/advertise/advertise.model';
import { AdvertiseService } from '../../core/services/advertise/advertise.service';
import { Lightbox } from 'ngx-lightbox';
import { Meta, Title } from '@angular/platform-browser';

@Component({
	selector: 'app-advertise-with-us-common',
	templateUrl: './advertise-with-us-common.component.html',
	styleUrls: ['./advertise-with-us-common.component.scss'],
})
export class AdvertiseWithUsCommonComponent implements OnInit {
	advertise: AdvertiseModel = null;
	stateDirectories: AdvertiseState[] = [];
	currentSection: string = '';
	_adAlbums: any[] = [];

	benefitsLeft = [
		{ text: 'Strong Audience Demographic Target (55+)' },
		{ text: 'Cost-Effective' },
		{ text: 'Print Readers Are Focused' },
		{ text: 'High Response & Recall Rate' },
		{ text: 'Customers Trust Print Ads More' },
	];
	benefitsRight = [
		{ text: 'Flexibility' },
		{ text: 'Longevity' },
		{ text: 'Print Ads Drive Action' },
		{ text: 'Build Reputation & Trust' },
	];

	constructor(
		private spinner: NgxSpinnerService,
		private advertiseService: AdvertiseService,
		private _lightbox: Lightbox,
		private meta: Meta,
		private title: Title
	) {}

	ngOnInit(): void {
		this.setSEOData();
		this.getAdvertise();
	}

	private setSEOData() {
		this.title.setTitle('Advertise With Us - Reach Your Audience | Arvig Pages');
		this.meta.addTags([
			{
				name: 'description',
				content: 'Discover how to effectively advertise with us and reach your target audience through our platform.',
			},
			{ name: 'author', content: 'ArvigPages' },
			{
				name: 'keywords',
				content: 'advertising, local services, print ads, target audience, cost-effective advertising',
			},
		]);
		this.meta.addTag({ rel: 'canonical', href: 'https://www.arvigpages.com/advertise-with-us' });
	}

	async getAdvertise() {
		try {
			this.spinner.show();

			const response = await this.advertiseService.getAdvertiseDetails();
			const advertiseFromServer = response[0];

			// Map the advertise data using the defined interfaces
			this.advertise = {
				advertiseImage: 'http://' + advertiseFromServer.advertiseImage,
				advertiseHeaderText: advertiseFromServer.advertiseHeaderText,
				advertiseText: advertiseFromServer.advertiseText,
				phone: advertiseFromServer.phone,
				contactUrl: advertiseFromServer.contactUrl,
				checkboxImage: 'http://' + advertiseFromServer.checkboxImage,
				states: advertiseFromServer.states,
				stateDirectories: advertiseFromServer.stateDirectories.map((state: any) => ({
					stateName: state.stateName,
					stateImage: 'http://' + state.stateImage,
					directories: state.directories.map((directory: any) => ({
						directoryName: directory.directoryName,
						viewDirectoryUrl: directory.viewDirectoryUrl,
						viewCoverageAreaUrl: 'http://' + directory.viewCoverageAreaUrl,
					})),
				})),
			};

			// Assign state directories for use in the template
			this.stateDirectories = this.advertise.stateDirectories;

			this._prepareLightboxImages();
			this.spinner.hide();
		} catch (error) {
			console.error('Error fetching advertisement data:', error);
			this.spinner.hide();
		}
	}

	private _prepareLightboxImages(): void {
		// Use reduce to flatten the array
		this._adAlbums = this.advertise.stateDirectories.reduce((acc, state) => {
			const images = state.directories.map((directory) => ({
				src: directory.viewCoverageAreaUrl,
				caption: directory.directoryName,
				thumb: directory.viewCoverageAreaUrl,
			}));
			return acc.concat(images);
		}, []);
	}

	open(directoryName: string, index: number): void {
		const filteredImages = this._adAlbums.filter((album) => album.caption === directoryName);

		if (filteredImages.length > 0) {
			this._lightbox.open(filteredImages, 0, {
				centerVertically: true,
				fadeDuration: 0.2,
			});
		}
	}

	close(): void {
		this._lightbox.close();
	}

	navigateTo(url: string): void {
		if (typeof window !== 'undefined') {
			window.open(url, '_blank');
		}
	}

	scrollTo(stateName: string) {
		if (typeof document !== 'undefined') {
			const element = document.getElementById(stateName);
			if (element) {
				const navBarHeight = document.querySelector('nav')?.clientHeight || 0;
				const elementPosition = element.getBoundingClientRect().top + window.pageYOffset;
				const offsetPosition = elementPosition - navBarHeight - 20;
				if (typeof window !== 'undefined') {
					window.scrollTo({
						top: offsetPosition,
						behavior: 'smooth',
					});
				}
			}
		}
	}

	onSectionChange(sectionId) {
		this.currentSection = sectionId;
	}
}
