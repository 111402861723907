<app-navbar-style-one [directory]="directory"></app-navbar-style-one>
<app-homeone-banner
	(searchTermSelected)="onSearchTermChanged($event)"
	(locationChanged)="onLocationChanged($event)"
	[directory]="directory"
></app-homeone-banner>
<!-- Start Events Area -->
<section class="bg-f9f9f9">
	<div class="section">
		<div class="row">
			<div class="sidebar">
				<div class="events-item-list">
					<div class="page-title-area" *ngFor="let content of pageTitleContent">
						<div class="container">
							<div class="page-title-content">
								<ul>
									<ul style="cursor: pointer">
										<li><a routerLink="/">Home</a></li>
										<li *ngIf="directory">
											<a (click)="navigateToDirectoryHome()">{{ directory }}</a>
										</li>
										<li>{{ this.searchTerm }}</li>
									</ul>
								</ul>
								<h3>{{ searchTerm }} in {{ location }}</h3>
								<div *ngIf="companies.length > 0" style="display: flex; align-items: center">
									Sort:
									<p style="margin: 0 10px">
										<span
											class="sort-option"
											(click)="sortCompanies('aToZ')"
											[ngClass]="{ active: sortOption === 'aToZ' }"
											style="cursor: pointer"
										>
											A to Z
										</span>
										|
										<span
											class="sort-option"
											(click)="sortCompanies('bestMatch')"
											[ngClass]="{ active: sortOption === 'bestMatch' }"
											style="cursor: pointer"
										>
											Best Match
										</span>
										|
										<span
											class="sort-option"
											(click)="sortCompanies('distance')"
											[ngClass]="{ active: sortOption === 'distance' }"
											style="cursor: pointer"
										>
											Distance
										</span>
									</p>
									<div class="submit-btn" style="margin-left: 0px">
										<button (click)="navigateListView()">List View</button>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div *ngFor="let company of companies">
						<div
							class="single-events-box"
							(click)="onSelectCompany(company)"
							[ngClass]="{ selected: selectedCompany === company }"
							style="cursor: pointer"
						>
							<div class="row m-0">
								<div class="col-3 p-0">
									<div class="image">
										<img [src]="company.companyLogoImage" alt="image" />
										<a (click)="onSelectCompany(company); navigateToMicroSite()" class="link-btn"></a>
									</div>
								</div>
								<div class="col-7 p-0 d-flex align-items-center">
									<div class="content">
										<h3>
											<a (click)="onSelectCompany(company); navigateToMicroSite()">{{ company.company }}</a>
										</h3>
										<h6>{{ company.phone }}</h6>
										<h6>{{ company.fullAddress }}</h6>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<app-pagination
					style="align-items: center"
					[currentPage]="currentPage"
					[itemsPerPage]="itemsPerPage"
					[totalItems]="results[0]?.companySearchResults.length"
					(pageChanged)="changePage($event)"
				></app-pagination>
			</div>
			<div class="col-8">
				<div id="map" class="map-responsive"></div>
			</div>
		</div>
	</div>
</section>

<!-- End Events Area -->

<app-footer-style-one [quickLinks]="quickLinks" [directory]="directory"></app-footer-style-one>
