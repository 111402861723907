import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { environment } from 'src/environments/environment';
import { CategoryModel } from '../../models/category/category.model';

@Injectable({
	providedIn: 'root',
})
export class CategoryService {
	baseUrl = environment.ApiUrl;
	constructor(private _httpClient: HttpClient) {}

	/**
	 * @method {GET}
	 * @param {string}
	 * @route  /categories?directory'
	 * @returns {Promise<CategoryModel[]>}
	 * @description get categories
	 */

	async getAllCategories(directory: String): Promise<CategoryModel[]> {
		try {
			return await firstValueFrom(
				this._httpClient.get<CategoryModel[]>(`${this.baseUrl}categories?directory=${directory}`)
			);
		} catch (error) {}
	}
}
