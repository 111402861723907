import { Injectable } from '@angular/core';
import { SearchResultModel } from '../../models/searchResult/search.result.model';
import { firstValueFrom } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
	providedIn: 'root',
})
export class SearchResultService {
	baseUrl = environment.ApiUrl;

	constructor(private _httpClient: HttpClient) {}
	/**
	 * @method {GET}
	 * @param {string,string,string}
	 * @route  'searchResult?directory&city&searchTerm'
	 * @returns {Promise<DirectoryHomeModel[]>}
	 * @description get search results
	 */

	async getSearchResults(directory: string, locationName: string, searchTerm: string): Promise<SearchResultModel[]> {
		const params = { directory, locationName, searchTerm };
		try {
			return await firstValueFrom(this._httpClient.get<SearchResultModel[]>(`${this.baseUrl}searchResult`, { params }));
		} catch (error) {}
	}
}
