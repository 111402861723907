import { Injectable } from '@angular/core';
import { LandingModel } from '../../models/landing/landing.model';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { firstValueFrom } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class LandingService {
	baseUrl = environment.ApiUrl;
	constructor(private _httpClient: HttpClient) {}

	/**
	 * @method {GET}
	 * @param {string}
	 * @route  /categories?directory'
	 * @returns {Promise<CategoryModel[]>}
	 * @description get categories
	 */

	async getLandingDetails(): Promise<LandingModel> {
		try {
			return await firstValueFrom(this._httpClient.get<LandingModel>(`${this.baseUrl}landing`));
		} catch (error) {}
	}
}
