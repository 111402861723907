import { BrowserModule, provideClientHydration } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SelectDropDownModule } from 'ngx-select-dropdown';
import { NgxScrollTopModule } from 'ngx-scrolltop';
import { NgxTypedJsModule } from 'ngx-typed-js';
import { FormsModule } from '@angular/forms';
import { NgxPaginationModule } from 'ngx-pagination';
import { NgxSpinnerModule } from 'ngx-spinner';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeDemoOneComponent } from './components/pages/home/home.component';
import { AppDownloadComponent } from './components/common/app-download/app-download.component';
import { HomeoneBlogComponent } from './components/pages/advertise-with-us/advertise.with.us.component';
import { CategoryComponent } from './components/pages/category/category.component';
import { FeatureAdsComponent } from './components/pages/feature-ads/feature.ads.component';
import { HomeoneBannerComponent } from './components/pages/home/home-banner/homeone-banner.component';
import { FooterStyleOneComponent } from './components/common/footer-style-one/footer-style-one.component';
import { NavbarStyleOneComponent } from './components/common/navbar-style-one/navbar-style-one.component';
import { ComingSoonComponent } from './components/pages/coming-soon/coming-soon.component';
import { NotFoundComponent } from './components/pages/not-found/not-found.component';
import { FaqComponent } from './components/pages/faq/faq.component';
import { CategoriesComponent } from './components/pages/categories/categories.component';
import { MapListViewComponent } from './components/pages/map-list-view/map.list.view.component';
import { SearchListViewComponent } from './components/pages/search-list-view/search.list.view.component';
import { HttpClientModule } from '@angular/common/http';
import { PaginationComponent } from './components/common/pagination/pagination.component';
import { SideMapComponent } from './components/common/side-map/side-map.component';
import { LandingComponent } from './components/pages/landing/landing.component';
import { LandingBannerComponent } from './components/pages/landing/landing-banner/landing-banner.component';
import { CityComponent } from './components/pages/city/city.component';
import { LocationHomeComponent } from './components/pages/location-home/location-home.component';
import { SearchListViewBannerComponent } from './components/pages/search-list-view/search-list-view-banner/search-list-view-banner/search-list-view-banner.component';
import { GoogleTagManagerModule } from 'angular-google-tag-manager';
import { MicrositeComponent } from './components/pages/microsite/microsite.component';
import { LightboxModule } from 'ngx-lightbox';
import { AdvertiseWithUsCommonComponent } from './components/pages/advertise-with-us-common/advertise-with-us-common.component';
import { ContactUsComponent } from './components/pages/contact-us/contact-us.component';
import { TermsAndConditionsComponent } from './components/pages/terms-and-conditions/terms-and-conditions.component';
import { HashLocationStrategy, LocationStrategy, PathLocationStrategy } from '@angular/common';

@NgModule({
	declarations: [
		AppComponent,
		HomeDemoOneComponent,
		AppDownloadComponent,
		HomeoneBlogComponent,
		CategoryComponent,
		FeatureAdsComponent,
		HomeoneBannerComponent,
		FooterStyleOneComponent,
		NavbarStyleOneComponent,
		ComingSoonComponent,
		NotFoundComponent,
		FaqComponent,
		CategoriesComponent,
		MapListViewComponent,
		SearchListViewComponent,
		PaginationComponent,
		LandingComponent,
		LandingBannerComponent,
		CityComponent,
		LocationHomeComponent,
		SearchListViewBannerComponent,
		MicrositeComponent,
		AdvertiseWithUsCommonComponent,
		ContactUsComponent,
		TermsAndConditionsComponent,
	],
	imports: [
		BrowserModule,
		AppRoutingModule,
		BrowserAnimationsModule,
		CarouselModule,
		SelectDropDownModule,
		NgxTypedJsModule,
		FormsModule,
		NgxPaginationModule,
		NgxScrollTopModule,
		HttpClientModule,
		NgxSpinnerModule,
		SideMapComponent,
		LightboxModule,

		GoogleTagManagerModule.forRoot({
			id: 'G-YH4YHK7YX7',
		}),
	],
	providers: [provideClientHydration(), { provide: LocationStrategy, useClass: PathLocationStrategy }],
	bootstrap: [AppComponent],
})
export class AppModule {}
