<section class="blog-area with-events pt-70 pb-70">
	<div class="container" *ngFor="let Content of advertiseWithUsSection" style="background-color: whitesmoke">
		<div class="row">
			<div class="col-lg-3 col-md-12">
				<div class="products-details-image" style="margin-top: 10px; margin-bottom: 10px">
					<a class="d-block">
						<img style="width: 300px; height: 400px" [src]="Content.directoryCoverImage" alt="image" />
					</a>
				</div>
			</div>
			<div class="col-lg-8 col-md-12">
				<div class="products-details-desc" style="margin-top: 20px; margin-bottom: 10px">
					<div [innerHTML]="Content.advertiseWithUsText"></div>
				</div>
			</div>
		</div>
	</div>
</section>

<!-- Uncomment the upcoming events section if needed -->
<!-- <div class="col-lg-4 col-md-12">
                <div class="events-item-list">
                    <h2>Upcoming Events</h2>
                    <div class="single-events-item" *ngFor="let Content of singleEventsItem">
                        <span class="meta"><i class="flaticon-calendar"></i> {{ Content.date }}</span>
                        <h3><a routerLink="/{{ Content.link }}">{{ Content.title }}</a></h3>
                        <a routerLink="/{{ Content.link }}" class="link-btn"><i class="flaticon-right-arrow"></i></a>
                    </div>
                </div>
            </div> -->
