<section class="listings-area ptb-100 bg-f9f9f9">
	<div class="container">
		<div class="section-title" *ngFor="let Title of sectionTitle">
			<h2>{{ Title.title }}</h2>
		</div>

		<div class="listings-slides">
			<owl-carousel-o [options]="customOptions">
				<ng-template carouselSlide *ngFor="let Content of featureAds">
					<div class="single-listings-box">
						<div class="listings-image">
							<div class="listings-image-slides">
								<owl-carousel-o [options]="customOptions2">
									<ng-template carouselSlide>
										<div>
											<img [src]="Content.imageURL" alt="image" class="featured-ad-image" />
											<a href="{{ Content.adClickURL }}" target="_blank" rel="noopener noreferrer" class="link-btn"></a>
										</div>
									</ng-template>
								</owl-carousel-o>
							</div>
						</div>
					</div>
				</ng-template>
			</owl-carousel-o>
		</div>
	</div>

	<div class="divider2"></div>
</section>
