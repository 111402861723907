import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AutoCompleteModel } from '../../models/common/auto.complete.model';
import { firstValueFrom } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class AutoCompleteService {
	baseUrl = environment.ApiUrl;
	constructor(private _httpClient: HttpClient) {}

	/**
	 * @method {GET}
	 * @param {string, string,}
	 * @route  search/autocomplete?directory&searchTerm'
	 * @returns {Promise<AutoCompleteModel[]>}
	 * @description get autocomplete details
	 */

	async getAutoCompleteResults(directory: string, searchTerm: string): Promise<AutoCompleteModel[]> {
		const params = { directory, searchTerm };

		try {
			return await firstValueFrom(
				this._httpClient.get<AutoCompleteModel[]>(`${this.baseUrl}search/autocomplete`, {
					params,
				})
			);
		} catch (error) {}
	}
}
