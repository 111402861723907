<app-navbar-style-one></app-navbar-style-one>
<div class="main-banner-area">
	<div class="container">
		<div class="main-banner-content">
			<div class="row">
				<div class="col-lg-5">
					<h1>{{ advertise.advertiseHeaderText }}</h1>
					<div class="header-text">
						{{ advertise.advertiseText }}
					</div>
					<h3 style="text-align: left; margin-bottom: 5px">Call Us Today!</h3>
					<div class="row p-1">
						<div class="col-lg-5">
							<button class="phone-button p-3">{{ advertise.phone }}</button>
						</div>
						<div class="col-lg-5">
							<button class="contact-button p-3" routerLink="/contact-us">Contact Us</button>
						</div>
					</div>
				</div>
				<div class="col-lg-7">
					<img src="{{ advertise.advertiseImage }}" alt="image" class="adverise-with-us-image" />
				</div>
			</div>
		</div>
	</div>
</div>

<section class="listings-area pt-5">
	<div class="container">
		<div class="benefit-title">Benefits of Print Advertising</div>

		<div class="row p-3">
			<div class="col-md-6">
				<ul>
					<li *ngFor="let benefit of benefitsLeft">
						<img src="{{ advertise.checkboxImage }}" class="checkbox-image" />
						{{ benefit.text }}
					</li>
				</ul>
			</div>

			<div class="col-md-6">
				<ul>
					<li *ngFor="let benefit of benefitsRight">
						<div>
							<img src="{{ advertise.checkboxImage }}" class="checkbox-image" />
						</div>

						{{ benefit.text }}
					</li>
				</ul>
			</div>
		</div>

		<div class="directory-title">Our Directories</div>
		<div class="main-banner-content">
			<ul class="state-list">
				<li *ngFor="let state of advertise.states; let last = last" style="cursor: pointer">
					<a (click)="scrollTo(state.stateName)">{{ state.stateName }}<span *ngIf="!last"> | </span></a>
				</li>
			</ul>
		</div>

		<section
			*ngFor="let state of advertise.stateDirectories"
			class="state-section"
			(sectionChange)="onSectionChange($event)"
			scrollSpy
		>
			<div class="state-name" id="{{ state.stateName }}">{{ state.stateName }}</div>
			<div class="states-wrapper d-flex align-items-flex-start">
				<!-- State Image -->
				<div class="state-image col-md-5 col-lg-5">
					<img [src]="state.stateImage" alt="{{ state.stateName }}" />
				</div>

				<div class="state-directories col-md-7 col-lg-7">
					<div *ngFor="let item of state.directories; let i = index" class="directory-item">
						<div class="directory-name">{{ item.directoryName }}</div>
						<button class="phone-button p-3" (click)="navigateTo(item.viewDirectoryUrl)">View Directory</button>
						<button class="contact-button p-3" (click)="open(item.directoryName, i)">View Coverage Area</button>
					</div>
				</div>
			</div>
		</section>
	</div>
</section>
<app-footer-style-one></app-footer-style-one>
