<app-navbar-style-one [directory]="directory"></app-navbar-style-one>
<app-homeone-banner [directory]="directory" [selectedLocation]="selectedLocation"></app-homeone-banner>
<app-feature-ads [featureAds]="featureAds"></app-feature-ads>

<div
	class="container"
	style="margin-top: 90px; background-color: whitesmoke; margin-bottom: 70px"
	*ngFor="let item of locationDetails"
>
	<div class="row">
		<div class="col-lg-3 col-md-12">
			<div class="products-details-image" style="margin-top: 10px; margin-bottom: 10px">
				<a>
					<img src="{{ item.directoryCoverImage }}" alt="image" style="width: 300px; height: 400px" />
				</a>
			</div>
		</div>
		<div class="col-lg-8 col-md-12">
			<div class="products-details-desc" style="margin-top: 20px; margin-bottom: 10px">
				<h3>{{ item.locationName }}</h3>
				<div class="price">
					<span style="color: black">
						Search the online directory for business listings in {{ item.locationName }} and the surrounding area
					</span>
				</div>
				<div>
					<span style="color: black; font-weight: 600">Other cities included in this directory:</span>
					<ul
						class="state-list"
						style="list-style-type: none; padding-left: 0; margin: 0; display: inline-block; vertical-align: middle"
					>
						<li
							*ngFor="let city of item.otherCities; let last = last"
							(click)="setCityNameAsLocation(city.cityName)"
							style="cursor: pointer; display: inline-block"
						>
							<a>{{ city.cityName }}<span *ngIf="!last"> | </span></a>
						</li>
					</ul>
				</div>
				<span style="color: black; font-weight: 600"> History :</span>
				<div [innerHTML]="item.history"></div>
				<span style="color: black; font-weight: 600"> Explore :</span>
				<div *ngFor="let item of item.locationLinks">
					<li class="link-btn" style="cursor: pointer">
						<a href="{{ item.url }}" target="_blank" rel="noopener noreferrer">{{ item.title }}</a>
					</li>
				</div>
			</div>
		</div>
	</div>
</div>
<app-category [categories]="categories" [directory]="directory" [selectedLocation]="selectedLocation"></app-category>

<app-footer-style-one [quickLinks]="quickLinks" [directory]="directory"></app-footer-style-one>
