<!-- Start Footer Area -->
<!-- <footer class="footer-area" style="background-color: white; padding: 0; margin-top: 50px">
	<div class="container">
		<div class="row" *ngFor="let footer of siteFooters" style="margin: 0; padding: 0">
			<div class="col-lg-3 col-sm-5 col-md-10" style="margin-top: 2px; margin-bottom: 2px; margin-right: 2px">
				<div class="">
					<div class="single-footer-widget">
						<img src="{{ footer.footerLogoImage }}" alt="logo" style="height: auto; width: auto" />
					</div>
				</div>
				<div class="single-footer-widget">
					<h3>Contact Info</h3>
					<ul class="footer-contact-info">
						<li *ngIf="footer.phone1"><i class="bx bx-phone-call"></i>{{ footer.phone1 }}</li>
						<li *ngIf="footer.email"><i class="bx bx-envelope"></i>Email: {{ footer.email }}</li>
						<li *ngIf="footer.contactUsUrl">
							<i class="bx bxs-inbox"></i><a href="{{ footer.contactUsUrl }}">{{ footer.contactUsUrl }}</a>
						</li>
					</ul>

					<ul class="social-link">
						<li *ngIf="footer.facebook">
							<a [href]="getFullUrl(footer.facebook)" class="d-block" target="_blank">
								<i class="bx bxl-facebook"></i>
							</a>
						</li>
						<li *ngIf="footer.twitterX">
							<a [href]="getFullUrl(footer.twitterX)" class="d-block" target="_blank">
								<i class="bx bxl-twitter"></i>
							</a>
						</li>
						<li *ngIf="footer.instagram">
							<a [href]="getFullUrl(footer.instagram)" class="d-block" target="_blank">
								<i class="bx bxl-instagram"></i>
							</a>
						</li>
						<li *ngIf="footer.youtube">
							<a [href]="getFullUrl(footer.youtube)" class="d-block" target="_blank">
								<i class="bx bxl-youtube"></i>
							</a>
						</li>
					</ul>
				</div>
			</div>
		</div>
	</div>
</footer> -->
<!-- End Footer Area -->

<!-- Start Footer Area -->
<footer class="footer-area">
	<div class="container">
		<div class="row">
			<div class="col-lg-3 col-sm-6 col-md-6">
				<div class="single-footer-widget">
					<h3>About</h3>
					<ul class="link-list">
						<li *ngFor="let List of aboutUs">
							<a *ngIf="List.title === 'Terms & Conditions'" routerLink="/terms-and-conditions" href="{{ List.url }}"
								><i class="flaticon-left-chevron"></i>{{ List.title }}</a
							>
							<a *ngIf="List.title === 'Contact'" routerLink="/contact-us" href="{{ List.url }}"
								><i class="flaticon-left-chevron"></i>{{ List.title }}</a
							>
							<a *ngIf="List.title !== 'Terms & Conditions' && List.title !== 'Contact'" href="{{ List.url }}"
								><i class="flaticon-left-chevron"></i>{{ List.title }}</a
							>
						</li>
					</ul>
				</div>
			</div>

			<div class="col-lg-3 col-sm-6 col-md-6">
				<div class="single-footer-widget" *ngIf="quickLinks.length > 0">
					<h3>Quick Links</h3>

					<ul class="link-list">
						<li *ngFor="let List of quickLinks">
							<a
								*ngIf="List.title === 'Categories'"
								(click)="navigateCategories()"
								class="nav-link"
								style="cursor: pointer"
								routerLinkActive="active"
								[routerLinkActiveOptions]="{ exact: true }"
							>
								{{ List.title }}
							</a>
							<a
								*ngIf="List.title === 'Advertise With Us'"
								routerLink="/advertise-with-us"
								class="nav-link"
								routerLinkActive="active"
								[routerLinkActiveOptions]="{ exact: true }"
							>
								{{ List.title }}
							</a>
							<a
								*ngIf="List.title !== 'Categories' && List.title !== 'Advertise With Us'"
								href="{{ List.url }}"
								target="_blank"
								rel="noopener noreferrer"
							>
								<i class="flaticon-left-chevron"></i>{{ List.title }}
							</a>
						</li>
					</ul>
				</div>
			</div>

			<div class="col-lg-3 col-sm-6 col-md-6">
				<div class="single-footer-widget">
					<h3>Contact Info</h3>

					<ul class="footer-contact-info" *ngFor="let item of siteFooters">
						<li *ngIf="item.phone1">
							<i class="bx bx-phone-call"></i>
							<a href="tel:{{ item.phone1 }}">{{ item.phone1 }}</a>
						</li>
						<li *ngIf="item.email">
							<i class="bx bx-envelope"></i>
							Email: <a href="mailto:{{ item.email }}">{{ item.email }}</a>
						</li>

						<li *ngIf="item.contactUsUrl">
							<i class="bx bxs-inbox"></i><a href="{{ item.contactUsUrl }}">{{ item.contactUsUrl }}</a>
						</li>
					</ul>

					<ul class="social-link" *ngFor="let item of siteFooters">
						<li *ngIf="item.facebook">
							<a href="{{ item.facebook }}" class="d-block" target="_blank" rel="noopener noreferrer"
								><i class="bx bxl-facebook"></i
							></a>
						</li>
						<li *ngIf="item.twitterX">
							<a href="{{ item.twitterX }}" class="d-block" target="_blank" rel="noopener noreferrer"
								><i class="bx bxl-twitter"></i
							></a>
						</li>
						<li *ngIf="item.instagram">
							<a href="{{ item.instagram }}" class="d-block" target="_blank" rel="noopener noreferrer"
								><i class="bx bxl-instagram"></i
							></a>
						</li>
					</ul>
				</div>
			</div>

			<div class="col-lg-3 col-sm-6 col-md-6" *ngFor="let item of siteFooters">
				<div class="single-footer-widget">
					<img src="{{ item.footerLogoImage }}" alt="logo" />
				</div>
			</div>
		</div>

		<div class="copyright-area">
			<p>
				© Powered by
				<a target="_blank" rel="noopener noreferrer">Team - SolisTech</a>
			</p>
		</div>
	</div>

	<div class="footer-image text-center">
		<img src="assets/img/footer-image.png" alt="image" />
	</div>
</footer>
<!-- End Footer Area -->

<div class="go-top"><i class="bx bx-up-arrow-alt"></i></div>
