<app-navbar-style-one [directory]=""></app-navbar-style-one>
<app-landing-banner [states]="states"></app-landing-banner>
<!-- <app-feature-ads [featureAds]="featureAds"></app-feature-ads> -->
<!-- <app-app-download [mobileAppSection]="mobileAppSection"></app-app-download> -->
<br /><br />

<!-- <div class="section-title" *ngFor="let Title of sectionTitle">
	<h2>{{ Title.title }}</h2>
</div> -->

<div>
	<!-- <div class="main-banner-content" id="main-banner-content">
		<ul class="state-list">
			<li *ngFor="let state of states; let last = last" style="cursor: pointer">
				<a (click)="scrollTo(state.stateName)">{{ state.stateName }}<span *ngIf="!last"> | </span></a>
			</li>
		</ul>
	</div> -->

	<section (sectionChange)="onSectionChange($event)" scrollSpy>
		<div class="row" style="margin: auto">
			<div class="directory-item" *ngFor="let directory of directories">
				<!-- Assign an ID to each directory based on its state name -->
				<div
					id="{{ directory.stateName }}"
					class="single-blog-post"
					(click)="navigateHome(directory.directoryName)"
					style="cursor: pointer"
				>
					<div class="state-name">
						<h3>{{ directory.stateName }}</h3>
					</div>
					<div class="post-image">
						<a class="d-block">
							<img
								[src]="directory.directoryCoverImage"
								alt="{{ directory.directoryName }}"
								style="border-radius: 15px"
							/>
						</a>
					</div>
					<div class="listings-content">
						<ul class="state-list">
							Cities:
							<li *ngFor="let city of directory.cities">
								<a (click)="setCityNameAsLocation(directory.directoryName, city.cityName)" class="link-btn">{{
									city.cityName
								}}</a>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</div>
	</section>
	<app-footer-style-one [quickLinks]="quickLinks"></app-footer-style-one>
</div>
