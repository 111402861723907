import { Component, OnInit, Input } from '@angular/core';
import { DirectoryHomeService } from '../../core/services/directory-home/directory-home.service';
import { DirectoryHomeModel } from '../../core/models/directoryHome/directory.home.model';
import { QuickLinkModel } from './../../core/models/quickLink/quick.link.model';
import { FooterLink, HeaderFooterModel } from '../../core/models/headerFooter/header.footer.model';
import { HeaderFooterService } from '../../core/services/header-footer/header-footer.service';
import { Router } from '@angular/router';

@Component({
	selector: 'app-footer-style-one',
	templateUrl: './footer-style-one.component.html',
	styleUrls: ['./footer-style-one.component.scss'],
})
export class FooterStyleOneComponent implements OnInit {
	@Input() directory: string = '';
	@Input() quickLinks: QuickLinkModel[] = [];
	HeaderFooterData: any = null;
	aboutUs: FooterLink[] = [];
	siteFooters: any[] = [];

	constructor(
		private headerFooterService: HeaderFooterService,
		private router: Router
	) {}

	ngOnInit(): void {
		this.getQuickLinks();
	}

	async getQuickLinks() {
		try {
			this.HeaderFooterData = await this.headerFooterService.getHeaderAndFooter(this.directory);

			let aboutUsFromServer = this.HeaderFooterData[0].footerLinks;

			aboutUsFromServer.forEach((element) => {
				this.aboutUs.push({
					groupName: element.groupName,
					title: element.title,
					url: this.getFullUrl(element.url),
				});
			});

			let siteFooterFromServer = this.HeaderFooterData[0].siteFooter;
			//console.log(this.HeaderFooterData);
			siteFooterFromServer.forEach((element) => {
				this.siteFooters.push({
					sortOrder: element.sortOrder,
					footerLogoImage: 'http://' + element.footerLogoImage,
					phone1: element.phone1,
					phone2: element.phone2,
					email: element.email,
					contactUsUrl: this.getFullUrl(element.contactUsUrl),
					facebook: this.getFullUrl(element.facebook),
					twitterX: this.getFullUrl(element.twitterX),
					youtube: this.getFullUrl(element.youtube),
					instagram: this.getFullUrl(element.instagram),
				});
				//console.log(element.footerLogoImage);
			});
		} catch (error) {}
	}
	getFullUrl(url: string): string {
		if (!url) {
			return ''; // Return empty string or handle missing URLs as needed
		}
		return url.startsWith('http') ? url : 'http://' + url;
	}

	navigateToCity(url: string): void {
		if (url) {
			if (!url.startsWith('http://') && !url.startsWith('https://')) {
				url = `http://${url}`;
			}
			if (typeof window !== 'undefined') {
				window.location.href = url;
			}
		}
	}
	navigateCategories() {
		this.router.navigate(['/categories'], {
			queryParams: { directory: this.directory },
		});
	}
}
