<app-navbar-style-one [directory]="directory"></app-navbar-style-one>

<app-homeone-banner [directory]="directory"></app-homeone-banner>
<!-- Start Category Area -->
<div class="container">
	<div *ngFor="let category of categories">
		<br />
		<div class="section-title">
			<h3>{{ category.categoryName }}</h3>
		</div>
		<div class="row">
			<div class="col-lg-2 col-sm-6 col-md-4" *ngFor="let tag of category.categoryTags">
				<div class="single-category-box">
					<!-- <div class="icon">
						<img src="{{ tag.categoryIcon }}" />
					</div> -->
					<span (click)="setCategorySearchTerm(tag.searchTerm)">
						{{ tag.title }}
					</span>
				</div>
			</div>
		</div>
	</div>
</div>
<!-- End Category Area -->

<app-footer-style-one [quickLinks]=""></app-footer-style-one>
