<app-navbar-style-one></app-navbar-style-one>
<section class="contact-info-area pt-100">
	<div class="container">
		<div class="row"></div>
	</div>
</section>

<div id="ajax-content-wrap">
	<div class="container-wrap">
		<div class="container main-content" role="main" style="--nectar-sticky-top-distance: 105.111px">
			<div class="row">
				<div
					id="fws_66dcf53e70f28"
					data-column-margin="default"
					data-midnight="dark"
					class="wpb_row vc_row-fluid vc_row top-level"
					style="padding-top: 20px; padding-bottom: 0px"
				>
					<div class="row-bg-wrap" data-bg-animation="none" data-bg-animation-delay="" data-bg-overlay="false">
						<div class="inner-wrap row-bg-layer"><div class="row-bg viewport-desktop" style=""></div></div>
					</div>
					<div class="row_col_wrap_12 col span_12 dark left">
						<div
							class="vc_col-sm-12 wpb_column column_container vc_column_container col no-extra-padding inherit_tablet inherit_phone"
							data-padding-pos="all"
							data-has-bg-color="false"
							data-bg-color=""
							data-bg-opacity="1"
							data-animation=""
							data-delay="0"
						>
							<div class="vc_column-inner">
								<div class="wpb_wrapper">
									<div class="wpb_text_column wpb_content_element">
										<div class="wpb_wrapper">
											<h1 style="text-align: center">MEDIA ADVERTISING CONTRACT TERMS AND CONDITIONS</h1>
											<p>
												<span style="text-decoration: underline"><strong>Parties.</strong></span> The Advertising
												Agreement is between Arvig Enterprises, Inc. or any subsidiary thereof (hereinafter “Arvig
												Media®”) and Customer as identified in the Service Agreement. The Customer agrees to pay Arvig
												Media in advance at the rate specified in the Service Agreement and Arvig Media agrees to
												advertise for Customer as agreed herein.
											</p>
											<h3>Service Agreement:</h3>
											<p>
												By signing this agreement, Customer agrees to pay the amount stated and for the term indicated
												on the Service Agreement. Customers who terminate service without fulfilling the Service
												Agreement, will be charged all remaining costs that would have been due to the Arvig Media under
												the terms of the agreement. All terms and conditions apply. Taxes and fees are in addition to
												the monthly rate.
											</p>
											<p>
												Arvig Media may require full payment in advance, monthly payment, annual payment, or as
												indicated on this contract for various services, all at Arvig Media’s sole and absolute
												discretion. Arvig Media may require full payment in advance, monthly payment, annual payment, or
												as indicated on this contract for certain products before work can be done as noted on the
												contract. Customer does not own nor have any rights other than those expressly granted to the
												Customer.
											</p>
											<p>
												<span style="text-decoration: underline"><strong>Ads.</strong></span> Arvig Media owns the
												visuals created or modified for the design of print ad(s).
											</p>
											<p>
												<span style="text-decoration: underline"><strong>Assignment.</strong></span> If the Customer
												sells or assigns their business, including but not limited to the business’s assets and
												intellectual property, this service agreement will be considered part of the sale or assignment,
												and any and all monies remaining due on the service agreement shall be due and payable from the
												new owner or assignee. Customer expressly agrees that if the new owner does not make payments,
												the Customer shall pay all such amounts in full upon written demand by Arvig Media. If redundant
												payments are received from both parties, covering any outstanding balance, Arvig Media will
												refund the redundant payment to the Customer.
											</p>
											<p>
												<span style="text-decoration: underline"><strong>Binding Agreement.</strong></span> Arvig Media
												will not be bound by any agreement or terms of any agreement or promise not expressly stated
												herein, nor shall anything relieve the Customer of their obligations hereunder unless in writing
												and signed by both parties hereto. Arvig Media reserves the right to reject any advertising from
												Customer for any reason or for no reason whatsoever. In such a case, Arvig Media will provide
												Customer with a written explanation of its decision and the basis therefore. Failure to
												acknowledge or return the proof will not alter existing payment terms or obligations.
											</p>
											<p>
												<span style="text-decoration: underline"><strong>Copyright Protection.</strong></span> Customer
												assumes sole responsibility for the protection of its copyright in any writing, pictorial
												illustration, design, map, photograph, or combination thereof included in its advertisement(s).
											</p>
											<p>
												<span style="text-decoration: underline"><strong>Design Changes & Revisions.</strong></span>
												Arvig Media will complete up to two rounds of revisions with the quoted price. Revision requests
												must be made within five (5) business days of receiving the proof. If no revisions are requested
												within this time, the submitted proof shall constitute the accepted final. Any revisions made
												after that will incur additional costs at Arvig Media’s current hourly rate. If the Customer
												requests drafts or revisions that go beyond the scope of the original quote or rate, Arvig Media
												reserves the right to charge an additional hourly rate upon informing the Customer that the
												request will incur an additional cost.
											</p>
											<p>
												<span style="text-decoration: underline"><strong>Duly Authorized.</strong></span> The person
												authorizing on behalf of Customer certifies the Customer duly authorizes them to enter into this
												agreement and has read, understands, and expressly agrees with the terms and conditions of this
												agreement. Customer acknowledges that Customer has requested modifications to the advertising
												program described and agrees to all such modifications.
											</p>
											<p>
												<span style="text-decoration: underline"><strong>Email Delivery.</strong></span> Arvig Media
												will use best practices to send online submissions to the Customer-supplied email address(es).
												Arvig Media does not guarantee deliverability to the Customer.
											</p>
											<p>
												<span style="text-decoration: underline"><strong>Errors and Omissions.</strong></span>
												Arvig Media‘s liability on account of error(s) in or omission(s) of such advertising shall in no
												event exceed the amount of charges for advertising which was omitted or in which the error
												occurred in the then-current service and such liability shall be discharged by an abatement of
												the charges for the particular service in which the error(s) or omission(s) occurred.
											</p>
											<p>
												<span style="text-decoration: underline"><strong>Issue Date.</strong></span> Arvig Media
												reserves the right to extend or reduce by not more than six (6) months the issue date and period
												of a publication. If the issue is extended Customer agrees to pay a prorated charge for the
												extended period.
											</p>
											<p>
												<span style="text-decoration: underline"><strong>Representations and Warranties.</strong></span>
												Customer hereby represents and warrants that it has the rights to use any proprietary
												information, including but not limited to trade secrets, trademarks, service marks, trade names,
												logos, copyrights, images, data figures, and the like. Customer is duly authorized to sell the
												product or service shown in the copy submitted for the advertisement or listing provided to
												Arvig Media on the website or advertising.
											</p>
											<p>
												<span style="text-decoration: underline"><strong>Renewal.</strong></span> Upon expiration of the
												original agreement term Arvig Media will attempt to contact Customer for any changes. If all
												attempts are unsuccessful before the print deadline, Arvig Media may include the current ad and
												bill Customer for the current rate of the ad.
											</p>

											<p><em>Last updated on September 9, 2024</em></p>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
<app-footer-style-one></app-footer-style-one>
