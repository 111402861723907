// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

/**
 * @file environment.ts
 * @description configurations development
 * @object environment
 * @version 1.0.0
 * @date 2024-07-01
 * @license SolisTech Pvt.Ltd. All Rights Reserved
 *
 */

export const environment = {
	// Indicates whether the application is running in production mode.
	// This is set to false for the development environment.
	production: false,

	// Base URL for the iypapi.arvigpages (Friends of Friends) API in the development environment.
	// This should point to the local instance of your API server during development.
	ApiUrl: 'https://iypapi.arvigpages.com/',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
