<!-- Start Main Banner Area -->
<div class="main-banner-area">
	<div class="container">
		<div class="main-banner-content">
			<div hidden>
				<H1>
					Explore top local businesses in various categories and states. Easily search by city or location on Arvig
					Pages for a seamless user experience.
				</H1>
			</div>
			<h1>Begin Your <span class="typing">&nbsp;Search</span></h1>
			<p>Select a business directory below</p>
			<ul class="state-list">
				<li *ngFor="let state of states; let last = last" style="cursor: pointer">
					<a (click)="scrollTo(state.stateName)">{{ state.stateName }}<span *ngIf="!last"> | </span></a>
				</li>
			</ul>
		</div>
	</div>
</div>
