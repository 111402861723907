<!-- Start Navbar Area -->
<div class="navbar-area navbar-style-two" [ngClass]="{ sticky: isSticky }">
	<div class="vesax-nav">
		<div class="container-fluid">
			<nav class="navbar navbar-expand-xl navbar-light" [class.active]="classApplied">
				<a class="navbar-brand" routerLink="/">
					<img src="{{ logo }}" alt="logo" />
				</a>
				<button class="navbar-toggler" type="button" (click)="toggleClass()">
					<span class="burger-menu">
						<span class="top-bar"></span>
						<span class="middle-bar"></span>
						<span class="bottom-bar"></span>
					</span>
				</button>
				<div class="collapse navbar-collapse">
					<ul class="navbar-nav">
						<li class="nav-item" *ngFor="let content of header">
							<!-- Home link -->
							<a
								*ngIf="content.title === 'Home'"
								routerLink="/"
								class="nav-link"
								routerLinkActive="active"
								[routerLinkActiveOptions]="{ exact: true }"
							>
								{{ content.title }}
							</a>
							<!-- Contact Us link -->
							<a
								*ngIf="content.title === 'Contact Us'"
								routerLink="/contact-us"
								class="nav-link"
								routerLinkActive="active"
								[routerLinkActiveOptions]="{ exact: true }"
							>
								{{ content.title }}
							</a>
							<!-- Advertise With Us link -->
							<a
								*ngIf="content.title === 'Advertise With Us'"
								routerLink="/advertise-with-us"
								class="nav-link"
								routerLinkActive="active"
								[routerLinkActiveOptions]="{ exact: true }"
							>
								{{ content.title }}
							</a>
							<!-- External links -->
							<a
								*ngIf="
									content.title !== 'Home' && content.title !== 'Contact Us' && content.title !== 'Advertise With Us'
								"
								[href]="content.url"
								class="nav-link"
								routerLinkActive="active"
								[routerLinkActiveOptions]="{ exact: true }"
							>
								{{ content.title }}
							</a>
						</li>
					</ul>
					<div class="others-option d-flex align-items-center"></div>
				</div>
			</nav>
		</div>
	</div>
</div>
