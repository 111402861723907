<app-navbar-style-one></app-navbar-style-one>

<section class="contact-info-area pt-100 pb-70">
	<div class="container">
		<div class="row">
			<div class="col-lg-4 col-md-6" *ngFor="let Content of contactInfoBox">
				<div class="contact-info-box">
					<div class="back-icon">
						<i class="{{ Content.icon }}"></i>
					</div>
					<div class="icon">
						<i class="{{ Content.icon }}"></i>
					</div>
					<h3>{{ Content.title }}</h3>
					<p *ngFor="let Text of Content.info" style="color: rgb(226, 226, 226)">{{ Text.text }}</p>
				</div>
			</div>
		</div>
	</div>
</section>

<!-- Start Contact Area -->
<section class="contact-area pb-100" style="margin-top: 20px">
	<div class="container">
		<div class="section-title" *ngFor="let Title of sectionTitle">
			<h2>{{ Title.title }}</h2>
			<p>{{ Title.paragraph }}</p>
		</div>

		<div class="row">
			<div class="col-lg-6 col-md-12">
				<div class="contact-image" *ngFor="let Image of contactImage">
					<img [src]="Image.img" alt="image" />
				</div>
			</div>

			<div class="col-lg-6 col-md-12">
				<div class="contact-form">
					<form #contactForm="ngForm" (ngSubmit)="submit(contactForm)">
						<div class="row">
							<!-- Business Name -->
							<div class="col-lg-12 col-md-6">
								<div class="form-group">
									<input
										required
										ngModel
										name="businessName"
										type="text"
										#businessName="ngModel"
										class="form-control"
										id="businessName"
										placeholder="Business Name"
									/>
									<div class="alert alert-danger" *ngIf="businessName.touched && !businessName.valid">
										Business name is required.
									</div>
								</div>
							</div>

							<!-- Contact Name -->
							<div class="col-lg-12 col-md-6">
								<div class="form-group">
									<input
										required
										ngModel
										name="contactName"
										type="text"
										#contactName="ngModel"
										class="form-control"
										id="contactName"
										placeholder="Contact Name"
									/>
									<div class="alert alert-danger" *ngIf="contactName.touched && !contactName.valid">
										Contact name is required.
									</div>
								</div>
							</div>

							<!-- Email -->
							<div class="col-lg-12 col-md-6">
								<div class="form-group">
									<input
										required
										ngModel
										name="email"
										type="email"
										#email="ngModel"
										class="form-control"
										id="email"
										placeholder="Your email address"
									/>
									<div class="alert alert-danger" *ngIf="email.touched && !email.valid">Email address is required.</div>
								</div>
							</div>

							<!-- Address -->
							<div class="col-lg-12 col-md-6">
								<div class="form-group">
									<input
										ngModel
										name="address"
										type="text"
										#address="ngModel"
										class="form-control"
										id="address"
										placeholder="Address"
									/>
								</div>
							</div>

							<!-- City -->
							<div class="col-lg-12 col-md-6">
								<div class="form-group">
									<input
										ngModel
										name="city"
										type="text"
										#city="ngModel"
										class="form-control"
										id="city"
										placeholder="City"
									/>
								</div>
							</div>

							<!-- State -->
							<div class="col-lg-12 col-md-6">
								<div class="form-group">
									<input
										ngModel
										name="state"
										type="text"
										#state="ngModel"
										class="form-control"
										id="state"
										placeholder="State"
									/>
								</div>
							</div>

							<!-- Zip Code -->
							<div class="col-lg-12 col-md-6">
								<div class="form-group">
									<input
										ngModel
										name="zipCode"
										type="text"
										#zipCode="ngModel"
										class="form-control"
										id="zipCode"
										placeholder="Zip Code"
									/>
								</div>
							</div>

							<!-- Phone -->
							<div class="col-lg-12 col-md-6">
								<div class="form-group">
									<input
										required
										ngModel
										name="phone"
										type="text"
										#phone="ngModel"
										class="form-control"
										id="phone"
										placeholder="Your phone number"
									/>
									<div class="alert alert-danger" *ngIf="phone.touched && !phone.valid">Phone number is required.</div>
								</div>
							</div>

							<!-- Message -->
							<div class="col-lg-12 col-md-12">
								<div class="form-group">
									<textarea
										required
										ngModel
										#message="ngModel"
										name="message"
										id="message"
										cols="30"
										rows="6"
										class="form-control"
										placeholder="Write your message (including the directory you are interested in)..."
									></textarea>
									<div class="alert alert-danger" *ngIf="message.touched && !message.valid">Message is required.</div>
								</div>
							</div>

							<!-- Submit Button -->
							<div class="col-lg-12 col-md-12">
								<button
									type="submit"
									class="default-btn"
									[class.disabled]="!contactForm.valid"
									[disabled]="!contactForm.valid"
								>
									Send Message
								</button>
							</div>
						</div>
					</form>
				</div>
			</div>
		</div>
	</div>
</section>
<!-- End Contact Area -->

<app-footer-style-one></app-footer-style-one>
