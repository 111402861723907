import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { firstValueFrom } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class EmailService {
	private apiUrl = 'https://api.brevo.com/v3/smtp/email';
	private apiKey = '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';

	constructor(private http: HttpClient) {}

	async sendEmail(form: any): Promise<any> {
		const emailData = {
			to: [{ email: 'advertise@arvigpages.com', name: 'Arvig Pages' }],
			sender: { email: 'kivindu@solistech.com', name: `${form.businessName}` },
			subject: 'New Contact Form Submission',
			htmlContent: `<p><strong>Business Name:</strong> ${form.businessName || 'Not Provided'}</p>
                    <p><strong>Contact Name:</strong> ${form.contactName || 'Not Provided'}</p>
                    <p><strong>Email:</strong> ${form.email || 'Not Provided'}</p>
                    <p><strong>Address:</strong> ${form.address || 'Not Provided'}</p>
                    <p><strong>City:</strong> ${form.city || 'Not Provided'}</p>
                    <p><strong>State:</strong> ${form.state || 'Not Provided'}</p>
                    <p><strong>Zip Code:</strong> ${form.zipCode || 'Not Provided'}</p>
                    <p><strong>Phone:</strong> ${form.phone || 'Not Provided'}</p>
                    <p><strong>Message:</strong> ${form.message || 'Not Provided'}</p>`,
		};

		console.log('Sending email with data:', emailData);

		const headers = new HttpHeaders({
			'Content-Type': 'application/json',
			'api-key': this.apiKey,
		});

		try {
			const response = await firstValueFrom(this.http.post(this.apiUrl, emailData, { headers }));
			console.log('Email sent successfully:', response);
			return { success: true };
		} catch (error) {
			console.error('Error sending email:', error);
			return { success: false, message: error.message };
		}
	}
}
