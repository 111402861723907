<!-- Start Category Area -->
<section class="pt-70">
	<div class="container">
		<div class="section-title">
			<h2>Categories</h2>
		</div>
		<div class="row">
			<div class="col-lg-2 col-sm-6 col-md-4" *ngFor="let Content of categories">
				<div class="single-category-box" [ngClass]="{ 'more-categories': Content.title === 'More Categories' }">
					<div class="icon">
						<img src="{{ Content.categoryIcon }}" />
					</div>
					<h3>{{ Content.title }}</h3>
					<span>{{ Content.numberOfPlaces }}</span>
					<a (click)="setCategorySearchTerm(Content.searchTerm)" class="link-btn"></a>
				</div>
			</div>
		</div>
	</div>
</section>
<!-- End Category Area -->
