import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
	selector: 'app-side-map',
	standalone: true,
	imports: [CommonModule],
	templateUrl: './side-map.component.html',
	styleUrls: ['./side-map.component.scss'],
})
export class SideMapComponent implements OnInit {
	apiKey: string = 'AIzaSyC-gkCR01R3SN8ofyKZO-ZQpPKop2t8vA8';
	markers: google.maps.Marker[] = [];
	map: google.maps.Map;
	infowindow: google.maps.InfoWindow;

	@Input() latitude: number = 0;
	@Input() longitude: number = 0;

	constructor() {}

	ngOnInit(): void {
		this.loadGoogleMapsScript(() => {
			this.initMap();
			this.updateMapCenter();
		});
	}

	loadGoogleMapsScript(callback: () => void) {
		if (typeof google !== 'undefined' && google.maps) {
			callback();
		} else {
			if (typeof document !== 'undefined') {
				if (typeof window !== 'undefined') {
					const script = document.createElement('script');
					script.src = `https://maps.googleapis.com/maps/api/js?key=${this.apiKey}&callback=initMap`;
					script.async = true;
					script.defer = true;
					window['initMap'] = () => {
						callback();
					};
					document.head.appendChild(script);
				}
			}
		}
	}

	initMap() {
		const mapOptions: google.maps.MapOptions = {
			zoom: 15,
			center: new google.maps.LatLng(this.latitude, this.longitude),
		};

		this.map = new google.maps.Map(document.getElementById('map') as HTMLElement, mapOptions);
		this.infowindow = new google.maps.InfoWindow();
		this.addMarker(this.latitude, this.longitude);
	}

	updateMapCenter() {
		if (this.map) {
			const newCenter = new google.maps.LatLng(this.latitude, this.longitude);
			this.map.setCenter(newCenter);
			this.addMarker(this.latitude, this.longitude);
		}
	}

	addMarker(lat: number, lng: number) {
		const position = new google.maps.LatLng(lat, lng);
		const marker = new google.maps.Marker({
			position,
			map: this.map,
		});
		this.markers.push(marker);
	}
}
