import { Component } from '@angular/core';
import Swal from 'sweetalert2';
import { EmailService } from '../../core/services/email/email.service';
import { Meta, Title } from '@angular/platform-browser'; // Import Meta and Title

@Component({
	selector: 'app-contact-us',
	templateUrl: './contact-us.component.html',
	styleUrls: ['./contact-us.component.scss'],
})
export class ContactUsComponent {
	/**
	 *
	 */
	constructor(
		private emailService: EmailService,
		private meta: Meta,
		private title: Title
	) {
		this.setSEOData(); // Set SEO data on component initialization
	}

	async submit(ContactForm: any) {
		console.log('Form data:', ContactForm.value);
		const form = ContactForm.value;
		const result = await this.emailService.sendEmail(form);
		if (result.success) {
			Swal.fire({
				icon: 'success',
				title: 'Message Sent!',
				text: 'Your message has been sent successfully.',
				confirmButtonColor: '#3085d6',
				confirmButtonText: 'OK',
			}).then(() => {
				ContactForm.reset();
			});
		} else {
			Swal.fire({
				icon: 'error',
				title: 'Oops...',
				text: 'There was an error sending your message. Please try again later.',
				confirmButtonColor: '#d33',
				confirmButtonText: 'Try Again',
			});
			//console.log(result.message);
		}
	}

	contactInfoBox = [
		{
			icon: 'bx bx-map',
			title: 'Our Address',
			info: [
				{
					text: '150 2nd St SW, Perham, MN 56573, United States',
				},
			],
		},
		{
			icon: 'bx bx-phone-call',
			title: 'Email Us',
			info: [
				{
					text: '888-442-8838',
				},
				{
					text: 'advertise@arvigpages.com',
				},
			],
		},
		{
			icon: 'bx bx-time-five',
			title: 'Hours of Operation',
			info: [
				{
					text: 'Monday - Friday: 8:00 am - 5:00 pm',
				},
				{
					text: 'Saturday & Sunday: Closed',
				},
			],
		},
	];

	sectionTitle = [
		{
			title: 'Ready to Get Started?',
			paragraph: 'A representative will reach out to you in 24 business hours.*',
		},
	];

	contactImage = [
		{
			img: 'assets/img/contact.png',
		},
	];

	// Method to set SEO data
	private setSEOData() {
		this.title.setTitle('Contact Us - Get in Touch with Arvig Pages');
		this.meta.addTags([
			{
				name: 'description',
				content: 'Contact Arvig Pages for any inquiries. A representative will respond within 24 business hours.',
			},
			{ name: 'keywords', content: 'Contact, support, Arvig Pages, inquiries' },
		]);
		this.meta.addTag({ rel: 'canonical', href: 'https://www.arvigpages.com/contact-us' });
	}
}
