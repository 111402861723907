import { Component, Input, OnInit } from '@angular/core';
import { AdvertiseWithUsSectionModel } from 'src/app/components/core/models/advertiseWithUsSection/advertise.with.us.section.model';
import { DirectoryHomeModel } from 'src/app/components/core/models/directoryHome/directory.home.model';
import { DirectoryHomeService } from 'src/app/components/core/services/directory-home/directory-home.service';

@Component({
	selector: 'app-advertise-with-us',
	templateUrl: './advertise.with.us.component.html',
	styleUrls: ['./advertise.with.us.component.scss'],
})
export class HomeoneBlogComponent implements OnInit {
	@Input() directory: string = '';
	directoryHomeData: DirectoryHomeModel = null;
	advertiseWithUsSection: AdvertiseWithUsSectionModel[] = [];

	constructor(private directoryHomeService: DirectoryHomeService) {}

	ngOnInit(): void {
		this.getAdvertiseWithUsSection();
	}

	async getAdvertiseWithUsSection() {
		try {
			this.directoryHomeData = await this.directoryHomeService.getDirectoryHomeResults(this.directory);
			let advertiseWithUsSectionFromServer = this.directoryHomeData[0].advertiseWithUsSection;

			for (let index = 0; index < 2; index++) {
				const element = advertiseWithUsSectionFromServer[index];
				this.advertiseWithUsSection.push({
					directoryCoverImage: 'http://' + element.directoryCoverImage,
					advertiseWithUsText: element.advertiseWithUsText,
				});
				//console.log('AdvertiseWithUS', this.advertiseWithUsSection);
			}
		} catch (error) {}
	}
}
