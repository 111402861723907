import { Component, Input, OnInit } from '@angular/core';
import { MobileAppSectionModel } from '../../core/models/mobileAppSection/mobile.app.section.model';

@Component({
	selector: 'app-app-download',
	templateUrl: './app-download.component.html',
	styleUrls: ['./app-download.component.scss'],
})
export class AppDownloadComponent implements OnInit {
	@Input() mobileAppSection: MobileAppSectionModel[] = [];
	constructor() {}

	ngOnInit(): void {}

	appDownloadContent = [
		{
			title: 'Download The Mobile App',
			paragraph: '',
			btnBox: [
				{
					img: 'assets/img/play-store.png',
					text: 'GET IT ON',
					subText: 'Google Play',
					link: '#',
				},
				{
					img: 'assets/img/apple-store.png',
					text: 'Download on the',
					subText: 'Apple Store',
					link: '#',
				},
			],
		},
	];
	appDownloadImage = [
		{
			img: 'assets/img/app-download.png',
		},
	];
}
