import { Component, NgZone, OnInit } from '@angular/core';
import { LocationHomeModel, LocationDetailsModel } from './../../core/models/locationHome/location.home.model';
import { FeaturedAdModel } from '../../core/models/featureAd/feature.ad.model';
import { QuickLinkModel } from '../../core/models/quickLink/quick.link.model';
import { ActivatedRoute, Router } from '@angular/router';
import { LocationHomeService } from '../../core/services/location-home/location-home.service';
import { DomSanitizer, Meta, Title } from '@angular/platform-browser';

@Component({
	selector: 'app-location-home',
	templateUrl: './location-home.component.html',
	styleUrl: './location-home.component.scss',
})
export class LocationHomeComponent implements OnInit {
	response: LocationHomeModel[] = [];
	featureAds: FeaturedAdModel[] = [];
	categories: any[] = [];
	searchTerm: string = '';
	directory: string = '';
	quickLinks: QuickLinkModel[] = [];
	locationDetails: LocationDetailsModel[] = [];
	selectedLocation: string = '';
	/**
	 *
	 */
	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private locationHomeService: LocationHomeService,
		private sanitizer: DomSanitizer,
		private ngZone: NgZone,
		private meta: Meta,
		private title: Title
	) {}

	ngOnInit(): void {
		this.directory = this.route.snapshot.paramMap.get('directory');
		this.selectedLocation = this.route.snapshot.paramMap.get('location');

		this.setSEOData(this.selectedLocation);

		this.loadData();
		this.loadData();
		this.scrollToTop();
	}

	scrollToTop() {
		this.ngZone.runOutsideAngular(() => {
			setTimeout(() => {
				window.scrollTo(0, 0);
			}, 0);
		});
	}

	async loadData() {
		try {
			this.response = await this.locationHomeService.getLocationHomeResults(this.directory, this.selectedLocation);
			console.log('locationHome', this.response);
			let featureAdsFromSever = this.response[0].featuredAds;
			//console.log('FeatureAds', featureAdsFromSever);
			for (let index = 0; index < featureAdsFromSever.length; index++) {
				let ad: FeaturedAdModel = new FeaturedAdModel();
				ad.imageURL = this.sanitizer.bypassSecurityTrustUrl(
					'http://' + featureAdsFromSever[index].featuredAdImage
				) as string;

				ad.adClickURL = 'http://' + featureAdsFromSever[index].adClickURL;
				ad.company = featureAdsFromSever[index].company;
				ad.featuredAdImage = featureAdsFromSever[index].featuredAdImage;

				this.featureAds.push(ad);
			}

			let locationDetailsFromServer = this.response[0].locationDetails;
			this.locationDetails = [];
			//console.log('locDet: ', locationDetailsFromServer);

			locationDetailsFromServer.forEach((element) => {
				this.locationDetails.push({
					directoryCoverImage: 'http://' + element.directoryCoverImage,
					history: element.history,
					locationLinks: element.locationLinks.map((link) => ({
						title: link.title,
						url: this.getFullUrl(link.url),
					})),
					locationName: element.locationName,
					otherCities: element.otherCities.map((city) => ({
						cityName: city.cityName,
						locationHomeUrl: 'http://' + city.locationHomeUrl,
					})),
				});
				console.log('links', this.locationDetails);
			});
			//console.log('locationDetails:', this.locationDetails);
			this.quickLinks = [];
			let quickLinksFromServer = this.response[0].quickLinks;
			quickLinksFromServer.forEach((element) => {
				this.quickLinks.push({
					groupName: element.groupName,
					title: element.title,
					url: this.getFullUrl(element.url),
				});
			});
			this.categories = [];
			this.response[0].categories.forEach((element) => {
				this.categories.push({
					title: element.title,
					searchTerm: element.searchTerm,
					categoryIcon: 'http://' + element.categoryIcon,
				});
			});

			this.categories.push({
				categoryIcon: 'assets/fonts/more-categories.svg',
				title: 'More Categories',
				searchTerm: 'More Categories',
			});
		} catch (error) {}
	}

	navigateToCity(url: string): void {
		if (url) {
			if (!url.startsWith('http://') && !url.startsWith('https://')) {
				url = `http://${url}`;
			}
			if (typeof window !== 'undefined') {
				window.location.href = url;
			}
		}
	}

	setCityNameAsLocation(location: string) {
		this.selectedLocation = location;
		this.router.navigate(['location-home/directory', this.directory, 'location', this.selectedLocation]).then(() => {
			this.setSEOData(location);
			this.loadData(); // Optionally trigger a manual refresh if needed
		});
	}

	private setSEOData(location: string) {
		this.title.setTitle(`${location} - Location Home | Arvig Pages`);
		this.meta.updateTag({
			name: 'description',
			content: `Explore services and information for ${location} in Arvig Pages.`,
		});
		this.meta.updateTag({ name: 'keywords', content: `location, ${location}, services, Arvig Pages` });
		const canonicalUrl = `https://www.arvigpages.com/location-home/directory/${this.directory}/location/${encodeURIComponent(location)}`;
		this.meta.updateTag({
			rel: 'canonical',
			href: canonicalUrl,
		});
	}

	getFullUrl(url: string): string {
		if (!url) {
			return ''; // Return empty string or handle missing URLs as needed
		}
		return url.startsWith('http') ? url : 'http://' + url;
	}
}
