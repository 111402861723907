import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { DirectoryHomeModel } from '../../models/directoryHome/directory.home.model';
import { firstValueFrom } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class DirectoryHomeService {
	baseUrl = environment.ApiUrl;
	constructor(private _httpClient: HttpClient) {}

	/**
	 * @method {GET}
	 * @param {string}
	 * @route  'directoryHome?director'
	 * @returns {Promise<DirectoryHomeModel[]>}
	 * @description get directory home detail list
	 */

	async getDirectoryHomeResults(directory: string): Promise<DirectoryHomeModel> {
		try {
			return await firstValueFrom(
				this._httpClient.get<DirectoryHomeModel>(`${this.baseUrl}directoryHome?directory=${directory}`)
			);
		} catch (error) {}
	}
}
