<app-navbar-style-one [directory]="directory"></app-navbar-style-one>

<app-search-list-view-banner
	(searchTermSelected)="onSearchTermChanged($event)"
	(locationChanged)="onLocationChanged($event)"
	[directory]="directory"
></app-search-list-view-banner>
<!-- Start Listings Area -->
<section class="listings-area ptb-100">
	<div class="container">
		<div class="row">
			<div class="col-lg-8 col-md-12">
				<div class="row">
					<div class="col-lg-12 col-md-12" *ngFor="let Content of companyDetails">
						<div class="single-listings-item">
							<div class="row m-0">
								<div class="col-lg-4 col-md-4 p-0">
									<div class="listings-image">
										<img src="{{ Content.companyLogoImage }}" alt="image" />
									</div>
								</div>

								<div class="col-lg-8 col-md-8 p-0">
									<div class="listings-content">
										<div class="company-info">
											<h3>
												{{ Content.company }}
											</h3>
											<span [ngClass]="Content.isPreferred === 'True' ? 'preferred-badge' : 'not-preferred-badge'">
												Preferred
											</span>
										</div>
										<ul class="listings-meta" style="padding: 10px">
											<!-- Phone Number -->
											<div *ngIf="Content.phone !== null" class="phone" style="margin-top: 10px">
												<li><i class="bx bx-phone-call"></i> {{ Content.phone }}</li>
											</div>

											<!-- Address -->
											<div *ngIf="Content.fullAddress !== null" class="address" style="margin-top: 10px">
												<li class="address"><i class="flaticon-pin"></i> {{ Content.fullAddress }}</li>
											</div>

											<!-- Open/Closed Status -->
											<div *ngIf="Content.todaysHours !== ''" class="time-display-wrapper" style="margin-top: 10px">
												<i class="flaticon-clock"></i>
												<div>
													<span *ngIf="Content.isOpen; else closedStatus" class="open-now-status">Open Now</span>
													<ng-template #closedStatus>
														<span class="closed-status">Closed</span>
													</ng-template>
													<div class="todays-hours">{{ Content.todaysHours }}</div>
												</div>
											</div>
										</ul>
									</div>
								</div>
							</div>
						</div>
						<div class="single-listings-item" style="padding: 10px">
							<h5>Description</h5>
							<p [innerHTML]="Content.companyDescription"></p>
						</div>
						<div
							*ngIf="Content.photos.length > 0 && Content.videos.length > 0"
							class="single-listings-item"
							style="padding: 10px"
						>
							<h5>Photos & Videos</h5>
							<div *ngIf="Content.photos.length > 0" class="row">
								<div class="col-md-4 listings-image-wrapper" *ngFor="let image of Content.photos; let i = index">
									<img
										[src]="image.photoImage"
										alt="Image"
										style="height: 200px; width: 200px; margin-bottom: 15px; cursor: pointer"
										(click)="open(i, 'image')"
									/>
									<div class="camera-icon" (click)="open(i, 'image')">
										<i class="bx bx-camera"></i>
									</div>
								</div>
							</div>

							<!-- <div *ngIf="Content.videos.length > 0" class="videos-container">
								<h6>Videos</h6>
								<div class="video-wrapper" *ngFor="let video of Content.videos">
									<video controls width="320" height="240">
										<source src="https://www.w3schools.com/html/mov_bbb.mp4" type="video/mp4" />
										Your browser does not support the video tag.
									</video>
								</div>
							</div> -->
							<div *ngIf="Content.videos.length > 0" class="videos-container">
								<h6>Videos</h6>
								<div class="row">
									<div class="col-md-6 mb-3" *ngFor="let video of Content.videos">
										<div class="video-wrapper">
											<iframe
												[src]="getSafeEmbedUrl(video.url)"
												title="YouTube video player"
												frameborder="0"
												allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
												allowfullscreen
											>
											</iframe>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div *ngIf="Content.printAds.length > 0" class="single-listings-item" style="padding: 10px">
							<h5>Advertisements</h5>

							<div class="row">
								<div class="col-md-4 listings-image-wrapper" *ngFor="let ad of Content.printAds; let j = index">
									<img
										[src]="ad.printAdImage"
										alt="Ad"
										style="height: 200px; width: 200px; margin-bottom: 15px; cursor: pointer"
										(click)="open(j, 'ad')"
									/>
									<div class="camera-icon" (click)="open(j, 'ad')">
										<i class="bx bx-camera"></i>
									</div>
								</div>
							</div>
						</div>
						<div *ngIf="Content.businessInfo !== ''" class="single-listings-item" style="padding: 10px">
							<h5>Business Info</h5>
							<p [innerHTML]="Content.businessInfo"></p>
						</div>
						<div *ngIf="relatedCategories.length > 0" class="single-listings-item" style="padding: 10px">
							<h5>Related Categories</h5>
							<div class="categories-container">
								<div *ngFor="let category of relatedCategories" class="category-item" style="cursor: pointer">
									<a (click)="setSearchTerm(category.searchTerm)">{{ category.title }}</a>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="col-lg-4 col-md-12">
				<aside class="listings-widget-area widget-right-sidebar">
					<section class="widget widget_filters" *ngIf="companyDetails[0]?.companyLinks.length > 0">
						<h3 class="widget-title">Contact Info</h3>
						<div *ngFor="let link of companyDetails[0]?.companyLinks">
							<ul>
								<li style="display: flex; align-items: center">
									<i *ngIf="link.label.toLowerCase() === 'phone'" class="bx bx-phone-call"></i>
									<i *ngIf="link.label.toLowerCase() === 'email'" class="bx bx-envelope"></i>
									<i *ngIf="link.label.toLowerCase() === 'website'" class="bx bx-globe"></i>
									<a
										*ngIf="!['facebook', 'twitter', 'instagram', 'youtube'].includes(link.label.toLowerCase())"
										[href]="link.url.startsWith('http') ? link.url : 'http://' + link.url"
										target="_blank"
										rel="noopener noreferrer"
										style="margin-left: 8px"
									>
										{{ link.url }}
									</a>
								</li>
							</ul>
							<div class="d-flex justify-content-between">
								<ul
									class="social-link"
									*ngIf="['facebook', 'twitter', 'instagram', 'youtube'].includes(link.label.toLowerCase())"
								>
									<li *ngIf="link.label.toLowerCase() === 'facebook'">
										<a
											[href]="link.url.startsWith('http') ? link.url : 'http://' + link.url"
											class="d-block"
											target="_blank"
											rel="noopener noreferrer"
										>
											<span><i class="bx bxl-facebook"></i></span>
										</a>
									</li>
									<li *ngIf="link.label.toLowerCase() === 'twitter'">
										<a
											[href]="link.url.startsWith('http') ? link.url : 'http://' + link.url"
											class="d-block"
											target="_blank"
											rel="noopener noreferrer"
										>
											<span><i class="bx bxl-twitter"></i></span>
										</a>
									</li>
									<li *ngIf="link.label.toLowerCase() === 'instagram'">
										<a
											[href]="link.url.startsWith('http') ? link.url : 'http://' + link.url"
											class="d-block"
											target="_blank"
											rel="noopener noreferrer"
										>
											<span><i class="bx bxl-instagram"></i></span>
										</a>
									</li>
									<li *ngIf="link.label.toLowerCase() === 'youtube'">
										<a
											[href]="link.url.startsWith('http') ? link.url : 'http://' + link.url"
											class="d-block"
											target="_blank"
											rel="noopener noreferrer"
										>
											<span><i class="bx bxl-youtube"></i></span>
										</a>
									</li>
								</ul>
							</div>
						</div>
					</section>

					<section class="widget widget_filters">
						<h3 class="widget-title">Map</h3>

						<div class="map-wrapper">
							<app-side-map
								*ngFor="let Content of companyDetails"
								[latitude]="Content.latitude"
								[longitude]="Content.longitude"
							></app-side-map>
						</div>
					</section>

					<section *ngIf="companyDetails[0]?.hoursOperation.length > 0" class="widget widget_filters">
						<div class="business-hours-container">
							<h3 class="widget-title" style="margin-right: 20px">Business Hours</h3>

							<!-- Display "Open Now" or "Closed" in the same row as the title -->
							<span *ngIf="companyDetails[0]?.isOpen; else closedStatus" class="open-now-status">Open Now</span>
							<ng-template #closedStatus>
								<span class="closed-status">Closed</span>
							</ng-template>
						</div>

						<ul *ngIf="companyDetails[0]?.hoursOperation.length > 0" class="hours-operation">
							<li *ngFor="let times of companyDetails[0]?.hoursOperation">
								<span>{{ times.weekdayName }}</span>
								<span>
									{{ times.isClosed ? 'Closed' : times.openTime + ' - ' + times.closeTime }}
								</span>
							</li>
						</ul>
					</section>

					<section *ngIf="companyDetails[0]?.otherLocations.length > 0" class="widget widget_filters">
						<h3 class="widget-title">Other Locations</h3>
						<ul>
							<li
								*ngFor="let location of companyDetails[0]?.otherLocations"
								class="col-lg-12"
								style="border-bottom: 2px solid #e2e2e2; margin-bottom: 5px; padding: 10px"
							>
								{{ location.description }}<br />
								{{ location.fullAddress }}<br />
								{{ location.phone }}
							</li>
						</ul>
					</section>
					<section *ngIf="preferredBusinesses.length > 0" class="widget widget_filters">
						<h3 class="widget-title">Preferred Businesses</h3>
						<ul>
							<li
								*ngFor="let business of preferredBusinesses"
								class="col-lg-12"
								style="border-bottom: 2px solid #e2e2e2; margin-bottom: 5px; padding: 10px"
							>
								<a
									style="color: #0678b1; font-size: 16px; font-weight: bold; cursor: pointer"
									(click)="setMicrosite(business.micrositeID)"
									>{{ business.company }}</a
								>
								<div style="font-weight: 600; color: var(--optionalColor)">{{ business.phone }}</div>
								{{ business.fullAddress }}
							</li>
						</ul>
					</section>
				</aside>
			</div>
		</div>
	</div>
</section>
<app-footer-style-one [quickLinks]="quickLinks" [directory]="directory"></app-footer-style-one>
