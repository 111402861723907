<app-navbar-style-one [directory]="directory"></app-navbar-style-one>

<app-search-list-view-banner
	(searchTermSelected)="onSearchTermChanged($event)"
	(locationChanged)="onLocationChanged($event)"
	[directory]="directory"
	[autoList]="autoList"
></app-search-list-view-banner>
<div class="banner-container" style="margin-top: 15px">
	<a *ngFor="let ad of bannerAds" [href]="ad.adClickURL" target="_blank" rel="noopener noreferrer">
		<img [src]="ad.bannerAdImage" class="banner-image" alt="Banner Image" />
	</a>
</div>

<!-- Start Page Title Area -->
<div class="page-title-area">
	<div class="container">
		<div class="page-title-content">
			<div *ngIf="companies.length === 0; else resultsTemplate">
				<ul>
					<ul style="cursor: pointer">
						<li><a routerLink="/">Home</a></li>
						<li *ngIf="directory">
							<a (click)="navigateToDirectoryHome()">{{ directory }}</a>
						</li>
						<li>{{ this.searchTerm }}</li>
					</ul>
				</ul>
				<div class="no-results">
					<h3>No results found for {{ searchTerm }} in {{ location === 'Everywhere' ? 'Your Area' : location }}</h3>
				</div>
			</div>

			<ng-template #resultsTemplate>
				<ul>
					<ul style="cursor: pointer">
						<li><a routerLink="/">Home</a></li>
						<li *ngIf="directory">
							<a (click)="navigateToDirectoryHome()">{{ directory }}</a>
						</li>
						<li>{{ this.searchTerm }}</li>
					</ul>
				</ul>
				<h3>{{ searchTerm }} in {{ location === 'Everywhere' ? 'Your Area' : location }}</h3>

				<!-- Conditionally hide sort options and map view -->
				<div *ngIf="companies.length > 0" style="display: flex; align-items: center">
					Sort:
					<p style="margin: 0 10px">
						<span
							class="sort-option"
							(click)="sortCompanies('aToZ')"
							[ngClass]="{ active: sortOption === 'aToZ' }"
							style="cursor: pointer"
						>
							A to Z
						</span>
						|
						<span
							class="sort-option"
							(click)="sortCompanies('bestMatch')"
							[ngClass]="{ active: sortOption === 'bestMatch' }"
							style="cursor: pointer"
						>
							Best Match
						</span>
						|
						<span
							class="sort-option"
							(click)="sortCompanies('distance')"
							[ngClass]="{ active: sortOption === 'distance' }"
							style="cursor: pointer"
						>
							Distance
						</span>
					</p>
					<div class="submit-btn" style="margin-left: 10px">
						<button (click)="navigateToMapView()">Map View</button>
					</div>
				</div>
			</ng-template>
		</div>
	</div>
</div>
<!-- End Page Title Area -->

<!-- Start Events Area -->
<section class="events-area bg-f9f9f9 pt-40 pb-70">
	<div class="container">
		<div class="row">
			<div class="col-lg-9 col-md-12">
				<div class="events-item-list" style="margin-right: 200px">
					<div
						class="single-events-box"
						*ngFor="let company of companies"
						(click)="navigateToMicroSite(company)"
						style="cursor: pointer"
						[style.backgroundColor]="company.yellowHighlight === 'True' ? '#FFFAC2' : 'transparent'"
					>
						<div class="row m-0">
							<div class="col-lg-3 col-md-12 p-0">
								<div class="image">
									<img [src]="company.companyLogoImage" alt="Company Logo" (click)="navigateToMicroSite(company)" />
								</div>
							</div>

							<div class="col-lg-9 col-md-12 p-0">
								<div class="content">
									<div [ngClass]="company.isPreferred === 'True' ? 'preferred-badge' : 'not-preferred-badge'">
										Preferred
									</div>

									<h3 style="color: #0678b1">
										<a (click)="navigateToMicroSite(company)">{{ company.company }}</a>
									</h3>
									<p>{{ company.fullAddress }}</p>
									<h6 style="font-size: 18px">{{ company.phone }}</h6>
									<a
										style="color: #0678b1"
										href="{{ 'http://' + company.website }}"
										target="_blank"
										rel="noopener noreferrer"
										>{{ company.website }}</a
									>
									<div
										class="company-desc"
										[innerHTML]="company.companyDescription"
										[ngStyle]="{ 'font-size': '13px' }"
									></div>

									<ul class="heading-list">
										<ul class="heading-list">
											<li *ngFor="let heading of company.headings; let last = last" style="cursor: pointer">
												<a (click)="stopPropagation($event); setHeadingSearchTerm(heading.headingName)">
													{{ heading.headingName }}
													<span *ngIf="!last">&nbsp;|&nbsp;</span>
												</a>
											</li>
										</ul>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="col-lg-3 col-md-12">
				<aside>
					<section *ngFor="let impAd of impactAds">
						<a [href]="impAd.adClickURL" target="_blank" rel="noopener noreferrer">
							<img
								[src]="impAd.impactAdImage"
								class=""
								style="margin-bottom: 25px; border: black; margin-left: 90px"
								alt="Banner Image"
							/>
						</a>
					</section>
				</aside>
			</div>
			<app-pagination
				[currentPage]="currentPage"
				[itemsPerPage]="itemsPerPage"
				[totalItems]="results[0]?.companySearchResults.length"
				(pageChanged)="changePage($event)"
			></app-pagination>
		</div>
	</div>

	<!-- Start Category Area -->
	<section class="pt-70" *ngIf="categories.length > 0">
		<div class="container">
			<div class="section-title">
				<h2>Related Categories</h2>
			</div>
			<div class="row">
				<ul class="category-list">
					<li
						*ngFor="let Content of categories"
						class="category-item"
						style="cursor: pointer"
						[ngClass]="{ 'more-categories': Content.title === 'More Categories' }"
					>
						<!-- Display the category name as a list item -->
						<a (click)="setCategorySearchTerm(Content.searchTerm)">{{ Content.title }}</a>
					</li>
				</ul>
			</div>
		</div>
	</section>
	<!-- End Category Area -->

	<div *ngFor="let item of headingDescription">
		<div style="margin-top: 50px; margin-bottom: 10px">
			<h4 style="text-align: center">{{ item.title }}</h4>
			<p
				class="company-desc"
				style="text-align: center; margin: 15px; padding: 10px"
				[innerHTML]="item.description"
			></p>
		</div>
	</div>
</section>
<!-- End Events Area -->

<app-footer-style-one [quickLinks]="quickLinks" [directory]="directory"></app-footer-style-one>
